import React from 'react';

import { PropertyNameIndexer } from '../../../../../typescript/customTypes';
import { SigningHubObject } from '../../../../../typescript/signingHubTypes';
import { StoreSettings, StoreTimesheet, StoreTimesheetCode } from '../../../../../typescript/storeTypes';
import { Week } from '../../../../../typescript/dateTypes';

import DateHelper from '../../../../../helpers/dateHelper';
import DigitalSigningWizardScreen1 from './DigitalSigningWizardScreen1';
import WeekHelper from '../../../../../helpers/weekHelper';
import WeekSelection from '../../../common/WeekSelection';

class DigitalSigningWizardWeekly extends React.PureComponent<Props> { 
    state = {
        selectedWeek: WeekHelper.getSelectedWeek(this.props.timesheet, this.props.settings)
    };

    updateSelectedWeek = (selectedWeek: Week|undefined): void => {
        this.setState({selectedWeek});
        
        this.props.updateSigningHubObject(Object.assign({}, this.props.signingHubObject, {startDate: selectedWeek && selectedWeek.startDate}), false);
    };

    hasEntries = (timesheetCode: StoreTimesheetCode): boolean => {
        const week = this.state.selectedWeek;
        if (!week) {
            return false;
        }

        return timesheetCode.entries.filter(entry => {
            const date = DateHelper.getNormalizedDate(new Date(entry.date));
            return date.valueOf() >= week.startDate.valueOf() && date.valueOf() <= week.endDate.valueOf();}).length > 0;
    };

    getFilteredTimesheetCodes = (): StoreTimesheetCode[] => {
        return this.props.timesheetCodes.slice().filter(timesheetCode => this.hasEntries(timesheetCode));
    };

    render() {
        return (
            <div className="digital-signing-wizard-weekly">
                <WeekSelection timesheet={this.props.timesheet}
                            settings={this.props.settings}
                            errors={this.props.errors}
                            selectedWeek={this.state.selectedWeek}
                            updateSelectedWeek={this.updateSelectedWeek} />
                <DigitalSigningWizardScreen1 timesheetCodes={this.getFilteredTimesheetCodes()}
                                             errors={this.props.errors}
                                             signingHubObject={this.props.signingHubObject}
                                             updateSigningHubObject={this.props.updateSigningHubObject}/>
            </div>
        );
    }
}

type Props = {
    timesheet: StoreTimesheet,
    settings: StoreSettings,
    signingHubObject: SigningHubObject,  
    timesheetCodes: StoreTimesheetCode[],
    errors: PropertyNameIndexer,
    updateSigningHubObject: (signingHubObject: SigningHubObject, revalidate: boolean) => void
};

export default DigitalSigningWizardWeekly;
