import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { LocationTypes } from '../../../types/locationTypes';
import {  Redirect, Route, RouteComponentProps, RouteProps, withRouter } from 'react-router-dom';
import { StoreState } from '../../../typescript/storeTypes';
import PermissionHelper from '../../../helpers/permissionHelper';

export class ProtectedRoute extends React.PureComponent<Props & MappedProps & RouteComponentProps> {
    render() {
        if (this.props.user.permissions.length > 0 && !PermissionHelper.hasPermission(this.props.user, this.props.permissionType)) {
            const renderComponent = () => (<Redirect to={{pathname: LocationTypes.HOME}}/>);
            return <Route {...this.props} component={renderComponent} render={undefined}/>;
        } else {
            return <Route {...this.props}/>;
        }
    }
}

const connector = connect(mapStateToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = RouteProps & {
    permissionType: string
};

function mapStateToProps(store: StoreState, props: Props) {
    return {
        user: store.user,

        permissionType: props.permissionType
    };
}

export default withRouter(connector(ProtectedRoute));
