import {AnyAction, Dispatch ,bindActionCreators} from 'redux';

import {ConnectedProps, connect} from 'react-redux';
import { StoreState} from '../../../../typescript/storeTypes';
import {getReleaseNotes} from '../../../../actions/releaseNotesActions';
import React from 'react';

import MarkDown from 'react-markdown';

export class ReleaseNotes extends React.PureComponent<Props> {
    render() {
        return (
            <div className="releasenotes">
                <MarkDown>
                    {this.props.notes}
                </MarkDown>
            </div>
        );
    }

    componentDidMount() {
        this.props.getReleaseNotes();
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState) {
    return {
        notes: state.releaseNotes.notes
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        getReleaseNotes
    }, dispatch);
}

export default connector(ReleaseNotes);
