import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { Interval } from '../../../types/enumTypes';
import { Job } from 'node-schedule';
import { MessageTypes, Messages } from '../../../types/messageTypes';
import { PermissionTypes } from '../../../types/permissionTypes';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { checkVersion, getMaintenanceDetails } from '../../../actions/maintenanceActions';
import { forceRefreshToken } from '../../../actions/userActions';
import { getTimesheet } from '../../../actions/timesheetActions';
import CronHelper from '../../../helpers/cronHelper';
import DateHelper from '../../../helpers/dateHelper';
import ImportActuals from './import/ImportActuals';
import IncompleteMonthsToast from '../../../common/IncompleteMonthsToast';
import InvoiceViewer from './invoice/InvoiceViewer';
import Login from './login/Login';
import MaintenanceHelper from '../../../helpers/maintenanceHelper';
import MessageHelper from '../../../helpers/messageHelper';
import Modal from './../../../common/Modal';
import ProtectedRoute from '../common/ProtectedRoute';
import React from 'react';
import RefreshToast from '../../../common/RefreshToast';
import ReleaseNotes from './releasenotes/ReleaseNotes';
import Reporting from './reporting/Reporting';
import Timesheet from './timesheet/Timesheet';

import { GroupTypes } from '../../../types/groupTypes';
import { LocationTypes, ValidRedirects } from '../../../types/locationTypes';
import { StoreState } from '../../../typescript/storeTypes';
import UserHelper from '../../../helpers/userHelper';
import WindowHelper from '../../../helpers/windowHelper';

export class Content extends React.Component<Props> {
    loggedInJob: Job|null = null;
    checkVersionJob: Job|null = null;
    maintenanceToastId: string|number =  9999;
    versionToastId: string|number = 9998;
    incompleteMonthsToastId: string|number = 9997;
    invalidPayrollToastId: string|number = 9996;

    checkVersionShown = false;

    showIncompleteMonthsAllowed = (): boolean => {
        return this.props.history.location.pathname === LocationTypes.HOME;
    };

    userStoppedImpersonating = (prevProps: Props): boolean => !UserHelper.isImpersonating(this.props.user) && UserHelper.isImpersonating(prevProps.user);
    incompleteMonthsChanged = (prevProps: Props): boolean => {
        if(this.props.incompleteMonths.length !== prevProps.incompleteMonths.length) return true;

        for(const incompleteMonth of prevProps.incompleteMonths) {
            if(!this.props.incompleteMonths.some(e => e.month === incompleteMonth.month && e.year === incompleteMonth.year)) return true;
        }

        return false;
    };

    canBypassMaintenanceMode = () => {
      return this.props.user.userGroups.some(ug => ug.username === this.props.user.authenticatedUser.username && ug.groups.some(g => g === GroupTypes.BYPASS_MAINTENANCEMODE));
    };

    getUserAndMaintenanceDetails = () => {
        if(this.canBypassMaintenanceMode()) return;

        this.props.getMaintenanceDetails();
    };

    componentDidMount() {
        this.getUserAndMaintenanceDetails();
        CronHelper.createNewJob(Interval.Every30Minutes, this.getUserAndMaintenanceDetails);
        this.loggedInJob = CronHelper.createNewJob(Interval.EveryHour, this.props.forceRefreshToken);
    }

    componentWillUnmount() {
        this.loggedInJob && CronHelper.stopCronJob(this.loggedInJob);
    }

    componentDidUpdate(prevProps: Props): void {
        if(!ValidRedirects.ForWebsite.includes(WindowHelper.getWindowLocation().pathname)) {
            WindowHelper.setWindowLocation(LocationTypes.HOME);
        }

        if(this.props.user.authenticatedUser.token && !this.props.user.authenticatedUser.hasActivePayroll && !MessageHelper.isActive(this.invalidPayrollToastId)) {
            this.invalidPayrollToastId = MessageHelper.showMessage(Messages.InactivePayroll, this.invalidPayrollToastId, false);
        } else if (this.props.user.authenticatedUser.hasActivePayroll && MessageHelper.isActive(this.invalidPayrollToastId)) {
            MessageHelper.dismiss(this.invalidPayrollToastId);
        }

        if(!MessageHelper.isActive(this.maintenanceToastId) && MaintenanceHelper.isMaintenanceModeConfigured(this.props.maintenanceDetails)) {
            this.maintenanceToastId = MessageHelper.showMessage({type: MessageTypes.INFO, message:MaintenanceHelper.getMaintenanceMessage(this.props.maintenanceDetails)});
        }

        if(!MessageHelper.isActive(this.versionToastId) && this.checkVersionShown === false && this.props.maintenanceDetails.isLatestVersion === false) {
            this.checkVersionShown = true;
            this.versionToastId = MessageHelper.showMessage({type: MessageTypes.INFO, message: <RefreshToast/>}, this.versionToastId, false);
        } else if (MessageHelper.isActive(this.versionToastId) && prevProps.maintenanceDetails.isLatestVersion === false && this.props.maintenanceDetails.isLatestVersion === true) {
            MessageHelper.dismiss(this.versionToastId);
            this.checkVersionShown = false;
        } else if(MessageHelper.isActive(this.versionToastId) && this.checkVersionShown === true) {
            this.checkVersionShown = false;
        }

        //Close the toast when a user is impersonating
        if(MessageHelper.isActive(this.incompleteMonthsToastId) && (UserHelper.isImpersonating(this.props.user) || !this.showIncompleteMonthsAllowed())) {
            MessageHelper.dismiss(this.incompleteMonthsToastId);
        }

        if (this.props.incompleteMonths && !UserHelper.isImpersonating(this.props.user)) {
            if(MessageHelper.isActive(this.incompleteMonthsToastId) && this.props.incompleteMonths.length === 0) {
                MessageHelper.dismiss(this.incompleteMonthsToastId);
            } else {
                if (this.props.incompleteMonths.length > 0 && this.showIncompleteMonthsAllowed()) {
                        const incompleteToast = <IncompleteMonthsToast incompleteMonths={this.props.incompleteMonths} getTimesheet={this.getTimesheet}/>;
                        if(!MessageHelper.isActive(this.incompleteMonthsToastId) && (this.incompleteMonthsChanged(prevProps) || this.userStoppedImpersonating(prevProps))) {
                            this.incompleteMonthsToastId = MessageHelper.showMessage({type: MessageTypes.INFO,message: incompleteToast}, this.incompleteMonthsToastId, false);
                        } else if(MessageHelper.isActive(this.incompleteMonthsToastId) && this.incompleteMonthsChanged(prevProps)) {
                            MessageHelper.update(this.incompleteMonthsToastId, {render: incompleteToast});
                        }
                }
            }
        }

        if(prevProps.user.authenticatedUser.token !== ''){
            if(this.checkVersionJob === null) {
                this.checkVersionJob = CronHelper.createNewJob(Interval.EveryHour, this.props.checkVersion);
                this.props.checkVersion();
            } else if(this.checkVersionJob && this.props.user.authenticatedUser.token === '') {
                CronHelper.stopCronJob(this.checkVersionJob);
                this.checkVersionJob = null;
            }
        } else if (this.checkVersionJob === null && this.props.user.authenticatedUser.token !== '') {
            this.checkVersionJob = CronHelper.createNewJob(Interval.Every10Minutes, this.props.checkVersion);
            this.props.checkVersion();
        }
    }

    getTimesheet = (month: number, year: number, event: Event) => {
        event.preventDefault();
        event.stopPropagation(); //to keep the toast from disappearing

        //Monthview only shows all the days for that month, but weekview could start on a monday in the previous month
        const startDate = this.props.settings.monthViewSelected
            ? DateHelper.getNormalizedDate(new Date(year, month - 1, 1))
            : DateHelper.getNormalizedDate(DateHelper.getMondayOfGivenWeek(new Date(year, month - 1, 1)));

        //Same as before, set the startdate on what is needed for each month.
        const newStartDate = this.props.settings.monthViewSelected ? DateHelper.firstDateOfMonth(startDate) : startDate;

        let endDate: Date;
        if(this.props.settings.monthViewSelected) {
            endDate = DateHelper.lastDateOfMonth(startDate);
        } else {
            endDate = DateHelper.addDays(startDate, 6);
        }

        const period = DateHelper.getPeriod(newStartDate, endDate);

        this.props.getTimesheet(period, this.props.user.employee);
    };

    render() {
        return (
            <div className="content">
                {this.props.maintenanceDetails.isInMaintenanceMode && (this.props.user.userGroups.length > 0 || this.props.user.authenticationError !== '') ?
                    <Modal>
                        <div className="inMaintenance">
                            {MaintenanceHelper.getMaintenanceMessage(this.props.maintenanceDetails)}
                        </div>
                    </Modal>
                :
                    <div className="default-content">
                        <ToastContainer
                            position="bottom-right"
                            autoClose={20000}
                            newestOnTop
                            closeOnClick
                            pauseOnHover
                        />

                        <Route exact path={LocationTypes.HOME} component={Timesheet}/>
                        <Route path={LocationTypes.REPORTING} component={Reporting} />
                        <ProtectedRoute permissionType={PermissionTypes.POST_IMPORT_ACTUALS} exact path={LocationTypes.IMPORT_ACTUALS} component={ImportActuals} />
                        <Route exact path={LocationTypes.RELEASE_NOTES} component={ReleaseNotes}/>
                        <ProtectedRoute permissionType={PermissionTypes.GET_INVOICEVIEWER_SUPPLIERS} exact path={LocationTypes.INVOICE_VIEWER} component={InvoiceViewer}/>


                        <Login/>
                    </div>
                }
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(store: StoreState, props: RouteComponentProps) {
    return {
        maintenanceDetails: store.maintenanceDetails,
        user: store.user,
        incompleteMonths: store.timesheet.incompleteMonths,
        settings: store.settings,
        history: props.history // routed
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        getMaintenanceDetails,
        forceRefreshToken,
        checkVersion,
        getTimesheet
    }, dispatch);
}

export default withRouter(connector(Content));
