import { AnyAction, Dispatch ,bindActionCreators} from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { LocationTypes } from '../../../../types/locationTypes';
import { PermissionTypes } from '../../../../types/permissionTypes';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DateRangePicker from '../../common/DateRangePicker';
import React from 'react';
import ScrollBar from 'react-custom-scrollbars-2';
import SearchInvoiceNumber from './SearchInvoiceNumber';
import SentInvoice from './SentInvoice';
import moment from 'moment';

import { DateRange } from 'react-day-picker';
import { StoreInvoiceViewerSupplier, StoreState } from '../../../../typescript/storeTypes';
import { clearInvoice, clearSentInvoices, getInvoice, getSentInvoices, searchInvoice } from '../../../../actions/invoiceActions';
import { getInvoiceViewerSuppliers } from '../../../../actions/userActions';
import PermissionHelper from '../../../../helpers/permissionHelper';
import TextInput from '../../../../common/TextInput';

export class InvoiceViewer extends React.Component<Props, State> {
    minDate = moment();
    maxDate = moment();
    state: State = {
        selectedSupplierId: 0,
        selectedInvoiceNumber: '',
        currentPage: 1,
        companyFilterValue: '',
        getInvoiceViewerSuppliersCalled: false,
        invoiceDateRangeStart: this.minDate.add(-2, 'months').endOf('month'),
        invoiceDateRangeEnd: this.maxDate,
        focusedInput: null
    };
    sentInvoice = React.createRef<SentInvoice>();

    onChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }): void => {
        this.setState({selectedInvoiceNumber: event.currentTarget.value});
    };

    searchInvoice = (invoiceNumber: string, download: boolean) => {
        if(invoiceNumber) {
            this.props.searchInvoice(invoiceNumber, download);
        }
    };

    resetSentInvoices = () => {
        this.props.clearInvoice();
        (this.sentInvoice.current as SentInvoice).callResetSentInvoices();
    };

    onFilterClick = () => {
        if((document.getElementById('js-invoice-viewer__title') as HTMLDivElement).style.visibility === 'hidden') {
            (document.getElementById('js-invoice-viewer__title') as HTMLDivElement).style.visibility = 'visible';
            (document.getElementById('js-invoice-viewer__filter__input') as HTMLDivElement).style.visibility = 'hidden';
            this.setState({companyFilterValue: ''});

            const filterElement = document.getElementsByClassName('fa-times')[0];
            filterElement.classList.remove('fa-times');
            filterElement.classList.add('fa-filter');
        } else {
            (document.getElementById('js-invoice-viewer__title') as HTMLDivElement).style.visibility = 'hidden';
            (document.getElementById('js-invoice-viewer__filter__input') as HTMLDivElement).style.visibility = 'visible';
            ((document.getElementById('js-invoice-viewer__filter__input') as HTMLDivElement).children[0].children[0] as HTMLInputElement).focus();

            const filterElement = document.getElementsByClassName('fa-filter')[0];
            filterElement.classList.remove('fa-filter');
            filterElement.classList.add('fa-times');
        }
    };

    getFilteredList = () => {
        if(this.props.user.invoiceViewerSuppliers.length === 0 || this.state.companyFilterValue === '') {
            return this.props.user.invoiceViewerSuppliers;
        }
        return this.props.user.invoiceViewerSuppliers.filter(ivs => ivs.name.toLowerCase().indexOf(this.state.companyFilterValue.toLowerCase()) !== -1);
    };

    static selectSupplierListItem = (props: Props, state: State, invoiceViewerSupplier: StoreInvoiceViewerSupplier) => {
        if(props.calls.invoiceRequested || state.selectedSupplierId === invoiceViewerSupplier.id) return state;

        props.clearSentInvoices();

        return Object.assign(state, {selectedSupplierId: invoiceViewerSupplier.id});
    };

    static getDerivedStateFromProps(nextProps: Props & MappedProps, prevState: State) {
        let state = null;
        if(PermissionHelper.hasPermission(nextProps.user, PermissionTypes.GET_INVOICEVIEWER_SUPPLIERS)) {
            if(nextProps.user.invoiceViewerSuppliers.length === 0 && !prevState.getInvoiceViewerSuppliersCalled) {
                nextProps.getInvoiceViewerSuppliers();

                state = { getInvoiceViewerSuppliersCalled: true };
            }
        }

        if(nextProps.user.invoiceViewerSuppliers && nextProps.user.invoiceViewerSuppliers.length === 1) {
            state = Object.assign(InvoiceViewer.selectSupplierListItem(nextProps, prevState, nextProps.user.invoiceViewerSuppliers[0]), state === null ? {} : state);
        }

        return state;
    }

    componentDidUpdate(prevProps: Props): void {
        if(prevProps.user !== this.props.user) {
            const canSearchInvoice = PermissionHelper.hasPermission(this.props.user, PermissionTypes.SEARCH_INVOICE);
            if(!canSearchInvoice) {
                this.props.history.push(LocationTypes.HOME);
                return;
            }
        }
    }

    componentDidMount() {
        if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_INVOICEVIEWER_SUPPLIERS) && this.props.user.invoiceViewerSuppliers.length < 1) {
            this.props.getInvoiceViewerSuppliers();
        }
    }

    componentWillUnmount() {
        this.props.clearInvoice();
    }

    render() {
        return (
            <div className="invoice-viewer">
                <div>
                    <div className="invoice-viewer__actions-container">
                        <div className="invoice-viewer__actions">
                            <SearchInvoiceNumber autoFocus />
                        </div>
                    </div>
                    <div className="invoice-viewer__selection-container">
                        <React.Fragment>
                            <div>
                                <div className="invoice-viewer__period-container">
                                    <div className="invoice-viewer__period-container__title">Period</div>
                                    <div className="invoice-viewer__period-container__picker">
                                        <DateRangePicker
                                            numberOfMonths={4}
                                            defaultFromDate={this.state.invoiceDateRangeStart.toDate()}
                                            defaultToDate={this.state.invoiceDateRangeEnd.toDate()}
                                            minDate={moment().add(-12, 'months').endOf('month').toDate()}
                                            maxDate={this.maxDate.toDate()}

                                            onDateRangeChange={(range: DateRange|undefined) => {
                                                const start = range?.from === null ? this.state.invoiceDateRangeStart :  moment(range?.from) as moment.Moment;
                                                const end = range?.to === null ? this.state.invoiceDateRangeEnd : moment(range?.to) as moment.Moment;
                                                this.setState({ invoiceDateRangeStart: start, invoiceDateRangeEnd: end }, () => {
                                                    this.resetSentInvoices();
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="invoice-viewer__navigation-container"/>
                                <div className="invoice-viewer__title-container">
                                    <div id="js-invoice-viewer__title" className="invoice-viewer__title">Companies</div>
                                    <div id="js-invoice-viewer__filter__input" className="invoice-viewer__filter__input">
                                        <TextInput name="filter" value={this.state.companyFilterValue} onChange={(event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => this.setState({companyFilterValue: event.currentTarget.value})}/>
                                    </div>
                                    {this.props.user.invoiceViewerSuppliers.length > 1 ?
                                    <div className="invoice-viewer__filter" onClick={this.onFilterClick}>
                                        <i className="fas fa-filter" />
                                    </div>
                                    : null}
                                </div>
                                <div className="invoice-viewer__supplier-list">
                                    <ScrollBar className="invoice-viewer__supplier-list--scroll-height">
                                        {this.getFilteredList().map(s =>
                                            <div key={`supplier-list-item-${s.id}`}
                                                id={`supplier-list-item-${s.id}`}
                                                className={`invoice-viewer__supplier-list-item ${this.state.selectedSupplierId === s.id ? 'invoice-viewer__supplier-list-item--selected' : ''} ${this.props.calls.invoiceRequested ? 'disabled' : ''}`}
                                                onClick={() => this.setState(InvoiceViewer.selectSupplierListItem(this.props, this.state, s), () => this.resetSentInvoices())}>
                                                {s.name}
                                            </div>
                                        )}
                                    </ScrollBar>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </div>
                <SentInvoice ref={this.sentInvoice}
                    supplierId={this.state.selectedSupplierId}
                    sentInvoices={this.props.invoiceDetails.sentInvoices}
                    invoiceDateRangeStart={this.state.invoiceDateRangeStart.format('YYYYMMDD')}
                    invoiceDateRangeEnd={this.state.invoiceDateRangeEnd.format('YYYYMMDD')}
                    getSentInvoices={this.props.getSentInvoices}
                    getInvoice={this.props.getInvoice}
                    clearSentInvoices={this.props.clearSentInvoices} />
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

type State = {
    selectedSupplierId: number,
    selectedInvoiceNumber: string,
    currentPage: number,
    companyFilterValue: string,
    getInvoiceViewerSuppliersCalled: boolean,
    invoiceDateRangeStart: moment.Moment,
    invoiceDateRangeEnd: moment.Moment,
    focusedInput: 'startDate' | 'endDate' | null
};

function mapStateToProps(state: StoreState, props: RouteComponentProps) {
    return {
        user: state.user,
        calls: state.calls,
        invoiceDetails: state.invoiceDetails,
        history: props.history // routed
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({searchInvoice, getSentInvoices, clearInvoice, clearSentInvoices, getInvoiceViewerSuppliers, getInvoice}, dispatch);
}

export default withRouter(connector(InvoiceViewer));
