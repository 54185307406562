import {isMobile} from 'react-device-detect';
import React from 'react';

class MobileRedirect extends React.PureComponent {
    componentDidMount(){
        if (isMobile) {
            if(process.env.ENDPOINT_MOBILE === undefined) return;
            window.location.assign(process.env.ENDPOINT_MOBILE);
        }
    }

    render() {
        return (isMobile ? <div className="redirect">Redirecting to the mobile website</div> : null);
    }
}

export default MobileRedirect;
