import {AnyAction, Dispatch, bindActionCreators} from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { StoreActuals, StoreState } from '../../../../typescript/storeTypes';
import { clearValidatedActuals, processImportActuals, validateActuals } from '../../../../actions/importActions';

import { LocationTypes } from '../../../../types/locationTypes';
import { PermissionTypes } from '../../../../types/permissionTypes';
import { ProgressTrackerStep } from '../../../../typescript/importTypes';
import { PropertyNameIndexer } from '../../../../typescript/customTypes';

import * as XLSX from 'xlsx';
import FileHelper from '../../../../helpers/fileHelper';
import FloatHelper from '../../../../helpers/floatHelper';
import ImportActualsRow from './ImportActualsRow';
import ImportActualsTemplateLegend from './ImportActualsTemplateLegend';
import MagicDropzone from 'react-magic-dropzone';
import NumberHelper from '../../../../helpers/numberHelper';
import ObjectHelper from '../../../../helpers/objectHelper';
import PermissionHelper from '../../../../helpers/permissionHelper';
import ProgressTracker from './ProgressTracker';
import React from 'react';


export class ImportActuals extends React.Component<Props, State> {
    state: State = {
        duplicates: [],
        steps: [ { text: 'Select an Excel document', isActive: true, isComplete: false, action: () => {this.resetSteps();} },
                 { text: 'Validating Actuals', isActive: false, isComplete: false },
                 { text: 'Import Actuals', isActive: false, isComplete: false },
                 { text: 'Importing Actuals', isActive: false, isComplete: false },
                 { text: 'Done', isActive: false, isComplete: false }
        ],
        nrOfRowsToImport: undefined,
        processImportActualsRequested: false
    };

    onDrop = (accepted: any): void => {
        FileHelper.enableReadAsBinaryStringOnFileReaderForLegacy();
        const reader = new FileReader();
        reader.onload = (e) => {
            let bstr;
            if(!e) {
                bstr = (reader as any).content;
            }
            else {
                if(e.target)
                {
                    bstr = e.target.result;
                }
            }

            const wb = XLSX.read(bstr, {type:'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            let data: StoreActuals[] = ObjectHelper.toCamel(XLSX.utils.sheet_to_json(ws, {header:0, })) as any;
            data = data.map(actual => ({
                ...actual,
                actualHours: FloatHelper.toFixedFloat(actual.actualHours),
                extraHours: FloatHelper.toFixedFloat(actual.extraHours),
                interventionHours: FloatHelper.toFixedFloat(actual.interventionHours),
                standByHours: FloatHelper.toFixedFloat(actual.standByHours)
            }));

            this.setState({steps: ImportActuals.nextStep(this.state.steps)});


            if (!this.verifyImportedData(data)) {
                return;
            }

            this.setState({nrOfRowsToImport: data.length});
            this.props.validateActuals(data);
        };
        this.props.clearValidatedActuals();
        this.setState({duplicates: [], nrOfRowsToImport: undefined, error: undefined});
        reader.readAsBinaryString(accepted[0].slice());
    };



    verifyImportedData = (rows: StoreActuals[]): boolean => {
       if (!rows || rows.length === 0) {
            this.setState({error: 'No valid rows were found. Unable to continue. Please check the provided template below for instructions.'});
            return false;
        }

        if (!this.verifyMissingColumns(rows)) {
            this.setState({error: 'One or more required columns are missing. Unable to continue. Please check the provided template below for instructions.'});
            return false;
        }

        const duplicates = this.sortRowsByDate(ObjectHelper.findDuplicates(rows, (r1, r2) => this.compareRows(r1 as StoreActuals,r2 as StoreActuals)));
        if (duplicates.length > 0) {
            this.setState({duplicates: duplicates,
                            error: 'The following duplicates were found. Unable to continue.'}
                        );
            return false;
        }

        for(let i = 0; i < rows.length; i++) {
            const row = rows[i];
            const rowIndex = i + 1;

            let isValid = true;
            isValid = isValid && this.sanitizeHoursProperty((row as PropertyNameIndexer), 'actualHours', rowIndex);
            isValid = isValid && this.sanitizeHoursProperty((row as PropertyNameIndexer), 'extraHours', rowIndex);
            isValid = isValid && this.sanitizeHoursProperty((row as PropertyNameIndexer), 'interventionHours', rowIndex);
            isValid = isValid && this.sanitizeHoursProperty((row as PropertyNameIndexer), 'standByHours', rowIndex);
            isValid = isValid && this.verifyTsCodeLength(row, rowIndex);

            if(!isValid) return false;
        }

        if(this.hasInvalidAmountOf(rows, NumberHelper.getAmountOfDecimals)) {
            this.setState({error: 'One or more hours have an invalid amount of decimals (more than 2). Unable to continue. Please check the provided template below for instructions.'});
            return false;
        }

        if(this.hasInvalidAmountOf(rows, NumberHelper.getAmountOfIntegers)) {
            this.setState({error: 'One or more hours have an invalid amount of whole numbers (more than 2). Unable to continue. Please check the provided template below for instructions.'});
            return false;
        }

        else {
            this.setState({error: undefined});
        }

        return true;
    };

    //Uren met een , omzetten naar een . indien nodig en daarna nakijken of de nieuwe uren geldige uren zijn.
    sanitizeHoursProperty = (row: PropertyNameIndexer, propertyName: string, rowIndex: number): boolean => {
        if(row[propertyName] && isNaN(row[propertyName])) {
            const hours = row[propertyName].toString().replace(/,/g,'.');
            if(hours.split('.').length > 2) {
                this.setState({error: `Invalid number format found for '${propertyName}' in row ${rowIndex}, the invalid value is: ${row[propertyName]}`});
                return false;
            }

            row[propertyName] =  parseFloat(hours);
        }

        if(row[propertyName] < 0) {
            this.setState({error: `Negative number found for '${propertyName}' in row ${rowIndex}, the invalid value is: ${row[propertyName]}`});
            return false;
        }

        return true;
    };

    hasInvalidAmountOf = (rows: StoreActuals[], getAmountOfFunction: (val: number|undefined) => number): boolean => {
        let isValid = true;
        for(const row of rows) {
            if(!isValid) return true;

            isValid = isValid && getAmountOfFunction(row.actualHours) <= 2;
            isValid = isValid && getAmountOfFunction(row.extraHours) <= 2;
            isValid = isValid && getAmountOfFunction(row.standByHours) <= 2;
            isValid = isValid && getAmountOfFunction(row.interventionHours) <= 2;
        }

        return !isValid;
    };

    verifyMissingColumns = (rows: StoreActuals[]): boolean => {
        if (rows.some(r => r.username === undefined
            || r.tsCode === undefined
            || r.workDay === undefined
            || r.workMonth === undefined
            || r.workYear === undefined
            || (r.actualHours === undefined
                && r.extraHours === undefined
                && r.standByHours === undefined
                && r.interventionHours === undefined))
               ) {
            return false;
        }
        return true;
    };

    verifyTsCodeLength = (row: StoreActuals, rowIndex: number) : boolean => {
        if(row.tsCode.length > 50) {
            this.setState({error: `Length of 'TsCode' in row ${rowIndex} exceeds the maximum length of 50, the invalid value is: ${row.tsCode}`});
            return false;
        }

        return true;
    };

    sortRowsByDate = (rows: StoreActuals[]): StoreActuals[] => {
        return rows.sort((r1: StoreActuals, r2: StoreActuals) => {
            const pad = (n: number) => n < 10 ? '0' + n : n;
            const day1 = `${r1.workYear}${pad(r1.workMonth)}${pad(r1.workDay)}`;
            const day2 = `${r2.workYear}${pad(r2.workMonth)}${pad(r2.workDay)}`;

            if (day1 < day2) return -1;
            if (day1 > day2) return 1;
            return 0;
        });
    };

    compareRows = (row1: StoreActuals, row2: StoreActuals): boolean => {
        return row1.username.toLocaleLowerCase() === row2.username.toLocaleLowerCase()
            && row1.tsCode.toLocaleLowerCase() === row2.tsCode.toLocaleLowerCase()
            && row1.workDay === row2.workDay
            && row1.workMonth === row2.workMonth
            && row1.workYear === row2.workYear;
    };

    checkForImportActualsToProcess = (): boolean => {
        return this.props.validatedActuals.actuals.some(va => va.processStatus === 'Ready to process');
    };

    processImportActuals = (): void => {
        const importId = this.props.validatedActuals.actuals[0].importId;
        this.setState({processImportActualsRequested: true});
        this.props.processImportActuals(importId);
    };

    resetSteps = (): void => {
        const steps = [ { text: 'Select an Excel document', isActive: true, isComplete: false, action: () => {this.resetSteps();} },
                        { text: 'Validating Actuals', isActive: false, isComplete: false },
                        { text: 'Import Actuals', isActive: false, isComplete: false },
                        { text: 'Importing Actuals', isActive: false, isComplete: false },
                        { text: 'Done', isActive: false, isComplete: false }
                    ];
        this.props.clearValidatedActuals();
        this.setState({steps: steps, duplicates: [], nrOfRowsToImport: undefined, error: undefined, processImportActualsRequested: false});
    };

    static nextStep(steps:ProgressTrackerStep[]): ProgressTrackerStep[] {
        const newSteps = steps.slice();
        const activeStep = newSteps.findIndex(s => s.isActive);
        const nextStep = activeStep < newSteps.length -1 ? activeStep + 1 : undefined;
        for (let i = 0; i <= activeStep; i++) {
            if (i === 0) {
                newSteps[0].text = 'Start over';
            }
            newSteps[i].isActive = false;
            newSteps[i].isComplete = true;
        }

        if(nextStep) {
            newSteps[nextStep].isActive = true;
        }

        return newSteps;
    }

    isLastStep = (): boolean => {
        return this.state.steps[this.state.steps.length -1].isComplete;
    };

    static getDerivedStateFromProps(nextProps: Props, state: State) {
        let newState = {steps: state.steps.slice()};

        if (nextProps.validatedActuals.actuals.length > 0) {
            newState = {steps: ImportActuals.nextStep(state.steps)};
        }

        if (nextProps.validatedActuals.actuals.find(va => va.processStatus !== 'Ready to process') !== undefined) {
            newState = Object.assign(newState, {error: 'Rows in red will not be processed.'});
        } else {
            newState = Object.assign(newState, {error: state.error});
        }

        if (nextProps.processImportActualsRequested) {
            newState = Object.assign(newState, {steps: ImportActuals.nextStep(newState.steps)});
        }

        if (state.processImportActualsRequested && !nextProps.processImportActualsRequested) {
            newState = Object.assign(newState, {steps: ImportActuals.nextStep(state.steps)});
        }

        return newState;
    }

    componentDidUpdate(prevProps: Props): void {
        if(prevProps.user !== this.props.user) {
            const canOpenImportActuals = PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_IMPORT_ACTUALS);
            if(!canOpenImportActuals) {
                this.props.history.push(LocationTypes.HOME);
            }
        }
    }

    componentWillUnmount() {
        this.resetSteps();
    }

    render() {
        return (
            <div className="import-actuals">
                <div className="import-actuals-content">
                    <ProgressTracker steps={this.state.steps} showStartOver={this.state.steps[0].text === 'Start over'} />
                    {this.state.error || this.props.validatedActuals.errorMessage ?
                        <div className="import-actuals-error-message">{this.state.error ? this.state.error : this.props.validatedActuals.errorMessage}</div>
                        : null
                    }

                    <div className="dropzone">
                        {this.checkForImportActualsToProcess() ?
                            <div className={!this.props.processImportActualsRequested ? 'dropzone-import-actuals' : 'dropzone-import-actuals-disabled'}>
                                <input type="submit" value="import actuals" onClick={this.processImportActuals} disabled={this.props.processImportActualsRequested} />
                                {this.props.processImportActualsRequested ?
                                    <div className="fa-stack">
                                        <i className="fas fa-circle-notch fa-spin fa-stack-1x" aria-hidden="true"/>
                                        <i className="fas fa-tasks fa-stack-2x" aria-hidden="true"/>
                                    </div>
                                    : null}
                            </div>
                            : null}
                        {this.props.validateActualsRequested ?
                            <div className="dropzone-content loading">
                                <div>
                                    <div className="dropzone-upload-logo fa-stack">
                                        <i className="fas fa-circle-notch fa-spin fa-stack-1x" aria-hidden="true"/>
                                        <i className="fas fa-tasks fa-stack-2x" aria-hidden="true"/>
                                    </div>
                                    <div>Validating {this.state.nrOfRowsToImport ? this.state.nrOfRowsToImport: ''} rows. Please wait...</div>
                                </div>
                            </div>
                            :
                            this.state.duplicates.length === 0 && this.props.validatedActuals.actuals.length === 0 && !this.isLastStep() ?
                                <MagicDropzone accept=".xlsx" onDrop={this.onDrop}>
                                    <div className="dropzone-content">
                                        <div>
                                            <div className="dropzone-upload-logo"><i className="fas fa-upload" aria-hidden="true"/></div>
                                            <div>Select an Excel (.xlsx) document or drag it here.</div>
                                        </div>
                                    </div>
                                </MagicDropzone>
                            : null
                        }
                    </div>
                    {this.state.duplicates.length > 0 || this.props.validatedActuals.actuals.length > 0 ?
                        <div className="import-actuals-row-header">
                            <div className="import-actuals-row-username">Username</div>
                            <div className="import-actuals-row-tscode">TsCode</div>
                            <div className="import-actuals-row-workday">Work Day</div>
                            <div className="import-actuals-row-workmonth">Work Month</div>
                            <div className="import-actuals-row-workyear">Work Year</div>
                            <div className="import-actuals-row-actualhours">Actual Hours</div>
                            <div className="import-actuals-row-extrahours">Extra Hours</div>
                            <div className="import-actuals-row-standbyhours">Standby Hours</div>
                            <div className="import-actuals-row-interventionhours">Intervention Hours</div>
                            <div className="import-actuals-row-comments">Comments</div>
                            <div className="import-actuals-row-processstatus">Process Status</div>
                        </div> : null
                    }
                    {(this.props.validatedActuals.actuals.length > 0 ? this.props.validatedActuals.actuals : this.state.duplicates).map((row: StoreActuals, i: number) =>
                        <ImportActualsRow key={`${i}${row.username}${row.tsCode}${row.workYear}${row.workMonth}${row.workDay}`} row={row} />
                    )}
                    {this.isLastStep() && !this.props.validatedActuals.errorMessage ?
                        <div>
                            <div className="import-actuals-success-message">Import actuals succeeded.</div>
                            <div className="import-actuals-success-startover" onClick={this.resetSteps}>
                                <div className="import-actuals-success-startover-text">Start over</div>
                                <div className="import-actuals-success-startover-icon">
                                    <i className="fas fa-redo" aria-hidden="true"/>
                                </div>
                            </div>
                        </div>
                         : null }
                </div>
                <div className="import-actuals-footer">
                    <ImportActualsTemplateLegend />
                </div>
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

type State = {
    duplicates: StoreActuals[],
    steps: ProgressTrackerStep[],
    nrOfRowsToImport?: number,
    error?: string,
    processImportActualsRequested: boolean
};

function mapStateToProps(state: StoreState, props: RouteComponentProps) {
    return {
        user: state.user,
        validatedActuals: state.validatedActuals,
        validateActualsRequested: state.calls.validateActualsRequested,
        processImportActualsRequested: state.calls.processImportActualsRequested,
        history: props.history // routed
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({validateActuals, clearValidatedActuals, processImportActuals}, dispatch);
}

export default withRouter(connector(ImportActuals));
