import {Action} from '../../../../typescript/actionTypes';
import { GroupTypes } from '../../../../types/groupTypes';
import { PermissionTypes } from '../../../../types/permissionTypes';
import {ProjectBasedTimesheetReportType} from '../../../../typescript/reportTypes';
import {SuggestionInfo} from '../../../../typescript/infoTypes';
import ApiHelper from '../../../../helpers/apiHelper';
import AutoSuggestList from './AutoSuggestList';
import Autosuggest, {GetSuggestionValue, InputProps} from 'react-autosuggest';
import EmployeeSortHelper from '../../../../helpers/employeeSortHelper';
import PermissionHelper from '../../../../helpers/permissionHelper';
import React from 'react';
import StringHelper from '../../../../helpers/stringHelper';
import TextInput from '../../../../common/TextInput';
import WildCardHelper from '../../../../helpers/wildCardHelper';

import {
    StoreActiveUserGroup,
    StoreBaseEmployeeName,
    StoreGroup,
    StoreProject,
    StoreSettings,
    StoreSupplier,
    StoreUser
} from '../../../../typescript/storeTypes';
import DateHelper from '../../../../helpers/dateHelper';
import MonthYearPicker from '../../common/MonthYearPicker';

class ProjectTimesheetReport extends React.Component<Props, State> {
    date = new Date();

    pickAMonth: any = React.createRef();

    state: State = {
        clientName: '',
        month: this.date.getMonth(),
        year: this.date.getFullYear(),
        selectedProject: null,
        filteredInternalProjectManagers: [],
        selectedInternalProjectManagerValue: '',
        selectedInternalProjectManager: null,
        externalProjectManager: '',
        ourReference: '',
        yourReference: '',
        contractReference: '',
        selectedTemplateSupplierValue: this.props.settings.defaultCompanyTemplateProject !== null ? this.props.settings.defaultCompanyTemplateProject.name : '',
        selectedTemplateSupplier: this.props.settings.defaultCompanyTemplateProject,
        userFilter: '',
        projectFilter: '',
        focusedInput: null
    };

    canExport = (): boolean => {
        if(this.props.reportRequested) return false;
        if(!this.state.selectedProject) return false;
        if(!this.state.month || !this.state.year) return false;
        if(!this.state.clientName) return false;
        if(!this.state.selectedInternalProjectManager) return false;
        if(!this.state.externalProjectManager) return false;
        if(!this.state.ourReference) return false;
        if(!this.state.yourReference) return false;
        if(!this.state.selectedTemplateSupplier) return false;

        return true;
    };

    getReportData = (): ProjectBasedTimesheetReportType => {
        let templateId = null;
        if (this.state.selectedTemplateSupplier !== null && this.state.selectedTemplateSupplier !== undefined) {
            templateId = this.state.selectedTemplateSupplier.id;
        }

        const startDate = new Date(this.state.year, this.state.month - 1, 1);
        DateHelper.resetTime(startDate);
        const endDate = DateHelper.lastDateOfMonth(startDate);
        DateHelper.resetTime(endDate);

        return {
           id: !this.state.selectedProject ? 0 : this.state.selectedProject.id,
           periodStart: startDate,
           periodEnd: endDate,
           clientName: this.state.clientName,
           internalProjectManagerId: (this.state.selectedInternalProjectManager as StoreActiveUserGroup).id,
           externalProjectManager: this.state.externalProjectManager,
           ourReference: this.state.ourReference,
           yourReference: this.state.yourReference,
           contractReference: this.state.contractReference,
           templateCompanyId: templateId
        };
    };

    generateReport = (): void => {
        if(!this.canExport()) return;

        const data = this.getReportData();
        this.props.generateProjectReport(data);
    };

    getProjectSuggestions = (): SuggestionInfo<StoreProject>[] => {
        let projects = this.props.projects.slice();

        if(this.state.projectFilter && this.state.projectFilter !== '') {
            projects = projects.filter(vs => WildCardHelper.containsString(this.state.projectFilter, vs.name));
        }

        const list: SuggestionInfo<StoreProject>[] = [];
        for (const project of projects) {
            if(list.length > 0 && list.some(e=>e.id === project.id)) continue;
            list.push({
                id: project.id,
                value: project,
                displayValue: project.name,
                currentCount: 0,
                totalCount: 0
            });
        }

        return list;
    };

    fetchProjects = (value: string) => this.setState({projectFilter: value});

    addProject = (projects: SuggestionInfo<StoreProject>[]): void => {
        const project = projects[0].value;
        this.setState({
            selectedProject: projects[0],
            clientName: project.clientName,
            selectedInternalProjectManagerValue: this.getStyledEmployeeFullName(project.internalProjectManager),
            selectedInternalProjectManager: project.internalProjectManager,
            externalProjectManager: project.externalProjectManager,
            ourReference: project.ourReference,
            yourReference: project.yourReference,
            contractReference: project.contractReference
        });
    };

    removeProject = (): void => {
        this.setState({selectedProject: null});
    };

    handleAMonthChange = (year: number, month: number) => {
        this.setState({year: year, month:month});
    };

    getProjectSelection = (): SuggestionInfo<StoreProject>[] => {
        if(!this.state.selectedProject) return [];
        return [this.state.selectedProject];
    };

    getSuppliers: (value: string) => void = ApiHelper.debounce((value: string) => this.props.getSuppliers(value), 500);

    onCompanyTemplateSuggestionsFetchRequested = ({value}: { value: string }) => this.getSuppliers(value);
    onCompanyTemplateSuggestionsClearRequested = this.props.clearSuppliers;
    getCompanyTemplateSuggestionValue: GetSuggestionValue<StoreSupplier> = (supplier: StoreSupplier): any => supplier;
    renderCompanyTemplateSuggestion = (supplier: StoreSupplier) => <div>{supplier.name}</div>;
    onCompanyTemplateChange = (event: React.FormEvent<HTMLElement>, {newValue}: { newValue: StoreSupplier | string }) => {
        if (newValue instanceof Object) {
            this.props.updateSetting('defaultCompanyTemplateProject', {id: newValue.id, name: newValue.name});
            this.setState({selectedTemplateSupplierValue: newValue.name, selectedTemplateSupplier: newValue});
        } else {
            const target = event.currentTarget as HTMLInputElement;
            this.setState({
                selectedTemplateSupplierValue: target.value,
                selectedTemplateSupplier: null
            });
        }
    };
    onCompanyTemplateBlur = (templateSupplier: StoreSupplier | null) => {
        if (this.state.selectedTemplateSupplierValue !== '' && this.state.selectedTemplateSupplier === null && templateSupplier === null) {
            this.setState({selectedTemplateSupplierValue: ''});
            return;
        }

        if (templateSupplier !== null) {
            this.setState({
                selectedTemplateSupplier: templateSupplier,
                selectedTemplateSupplierValue: templateSupplier.name
            });
        }
    };

    onClientNameChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => {
        this.setState({clientName: event.currentTarget.value});
    };

    onInternalProjectManagerSuggestionsFetchRequested = ({value}: { value: string })  => {
        if(value) {
            this.setState({filteredInternalProjectManagers: this.props.user.userGroups.filter(ug => EmployeeSortHelper.isHit(value, ug))});
        } else {
            this.setState({filteredInternalProjectManagers: this.props.user.userGroups});
        }
    };

    getStyledEmployeeFullName = (user: StoreBaseEmployeeName) => StringHelper.getEmployeeFullName(user, this.props.settings.firstNameLastName, false, true);

    onInternalProjectManagerSuggestionsClearRequested = () => { this.setState({filteredInternalProjectManagers: []}); };
    getInternalProjectManagerSuggestionValue: GetSuggestionValue<StoreActiveUserGroup> = (user: StoreActiveUserGroup): any => user;
    renderInternalProjectManagerSuggestion = this.getStyledEmployeeFullName;
    onInternalProjectManagerChange = (event: React.FormEvent<HTMLElement>, {newValue}: { newValue: StoreActiveUserGroup | string }) => {
        if (newValue instanceof Object) {
            this.setState({selectedInternalProjectManagerValue: `${this.getStyledEmployeeFullName(newValue as StoreActiveUserGroup)}`, selectedInternalProjectManager: newValue as StoreActiveUserGroup});
        } else {
            const target = event.currentTarget as HTMLInputElement;
            this.setState({
                selectedInternalProjectManagerValue: target.value,
                selectedInternalProjectManager: null
            });
        }
    };
    onInternalProjectManagerBlur = (internalProjectManager: StoreActiveUserGroup | null) => {
        if (this.state.selectedInternalProjectManagerValue !== '' && this.state.selectedInternalProjectManager === null && internalProjectManager === null) {
            this.setState({selectedInternalProjectManagerValue: ''});
            return;
        }

        if (internalProjectManager !== null) {
            this.setState({
                selectedInternalProjectManager: internalProjectManager,
                selectedInternalProjectManagerValue: this.getStyledEmployeeFullName(internalProjectManager)
            });
        }
    };

    onExternalProjectManagerChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => {
        this.setState({externalProjectManager: event.currentTarget.value});
    };

    onOurReferenceChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => {
        this.setState({ourReference: event.currentTarget.value});
    };

    onYourReferenceChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => {
        this.setState({yourReference: event.currentTarget.value});
    };

    onContractReferenceChange = (event: React.SyntheticEvent<HTMLInputElement> & { currentTarget: HTMLInputElement }) => {
        this.setState({contractReference: event.currentTarget.value});
    };

    componentDidUpdate(prevProps: Props) {
        if(this.props.user.permissions !== prevProps.user.permissions){
            if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_PROJECTS) && this.props.projects.length === 0) {
                this.props.getProjects();
            }
            if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_GROUPS) && this.props.groups.length === 0) {
                this.props.getGroups();
            }
        }

        if(this.props.groups !== prevProps.groups) {
            const allowedGroupIds = this.props.groups.filter(g => [
                GroupTypes.PROJECT_MANAGER,
                GroupTypes.BILLING_RESPONSIBLE,
                GroupTypes.PARTNER_COORDINATOR
            ].some(gr => g.name === gr))
            .map(g => g.id);
            this.props.getActiveUsersWithGroups(allowedGroupIds);
        }
    }

    componentDidMount() {
        if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_PROJECTS)) {
            this.props.getProjects();
        }
        if(PermissionHelper.hasPermission(this.props.user, PermissionTypes.GET_GROUPS)) {
            this.props.getGroups();
        }
    }

    render() {
        const inputPropsSupplier: InputProps<StoreSupplier> = {
            placeholder: '',
            value: this.state.selectedTemplateSupplierValue,
            onChange: (event: React.FormEvent<HTMLElement> & { currentTarget: HTMLElement }, newValue: { newValue: any }) => this.onCompanyTemplateChange(event, newValue),
            onBlur: (_, {highlightedSuggestion}: any) => this.onCompanyTemplateBlur(highlightedSuggestion)
        };

        const inputPropsInternalProjectManager: InputProps<StoreActiveUserGroup> = {
            placeholder: '',
            value: this.state.selectedInternalProjectManagerValue,
            onChange: (event: React.SyntheticEvent<HTMLElement> & { currentTarget: HTMLElement }, newValue: { newValue: any }) => this.onInternalProjectManagerChange(event, newValue),
            onBlur: (_, {highlightedSuggestion}: any) => this.onInternalProjectManagerBlur(highlightedSuggestion)
        };

        return (
            <div className="project-timesheet-report">
                <div className="project-selection">
                    {!this.state.selectedProject ?
                        <div className="project-selection-title">Select a project</div>
                        : <div className="project-selection-title"/>
                    }
                    <div className="project-selection-box">
                        <AutoSuggestList<StoreProject> listName="Projects"
                                            selected={this.getProjectSelection()}
                                            suggestions={this.getProjectSuggestions()}
                                            fetch={this.fetchProjects}
                                            selectSuggestion={this.addProject}
                                            unSelectSuggestion={this.removeProject}
                                            singleItemSelection
                                            showAllSuggestionsWhenEmptyFilterValue
                        />
                    </div>
                </div>
                {this.state.selectedProject ?
                    <div className="project-timesheet-information">
                        <div className="period-picker-container info-container">
                            <label>Period</label>
                            <MonthYearPicker
                                initialValue={DateHelper.firstDateOfMonth(DateHelper.addMonths(new Date(), -1))}
                                minDate={new Date(2021,0,1)}
                                maxDate={new Date(new Date().getFullYear(), DateHelper.getThisMonth()-1)}
                                onChange={(month: number, year: number) => this.handleAMonthChange(year, month)}
                            />
                        </div>
                        <div className="info-container">
                            <label>Client Name</label>
                            <div className="project-timesheet-clientname">
                                <TextInput name="clientName" placeholder="" onChange={this.onClientNameChange} value={this.state.clientName} />
                            </div>
                        </div>
                        <div className="info-container">
                            <label>Internal Project Manager</label>
                            <div className="project-timesheet-internalprojectmanager">
                                <Autosuggest suggestions={this.state.filteredInternalProjectManagers}
                                            onSuggestionsClearRequested={this.onInternalProjectManagerSuggestionsClearRequested}
                                            onSuggestionsFetchRequested={this.onInternalProjectManagerSuggestionsFetchRequested}
                                            getSuggestionValue={this.getInternalProjectManagerSuggestionValue}
                                            renderSuggestion={this.renderInternalProjectManagerSuggestion}
                                            inputProps={inputPropsInternalProjectManager}
                                            highlightFirstSuggestion />
                            </div>
                        </div>
                        <div className="info-container">
                            <label>External Project Manager</label>
                            <div className="project-timesheet-externalprojectmanager">
                                <TextInput name="externalProjectManager" placeholder="" onChange={this.onExternalProjectManagerChange} value={this.state.externalProjectManager} />
                            </div>
                        </div>
                        <div className="info-container">
                            <label>Our Reference</label>
                            <div className="project-timesheet-ourreference">
                                <TextInput name="ourReference" placeholder="" onChange={this.onOurReferenceChange} value={this.state.ourReference} />
                            </div>
                        </div>
                        <div className="info-container">
                            <label>Your Reference</label>
                            <div className="project-timesheet-yourreference">
                                <TextInput name="yourReference" placeholder="" onChange={this.onYourReferenceChange} value={this.state.yourReference} />
                            </div>
                        </div>
                        <div className="info-container">
                            <label>Contract Reference<div className="project-timesheet-optional">(optional)</div></label>
                            <div className="project-timesheet-contractreference">
                                <TextInput name="contractReference" placeholder="" onChange={this.onContractReferenceChange} value={this.state.contractReference} />
                            </div>
                        </div>
                        <div className="info-container">
                            <label>Company template</label>
                            <div className="project-timesheet-companytemplate">
                                <Autosuggest suggestions={this.props.suppliers}
                                            onSuggestionsClearRequested={this.onCompanyTemplateSuggestionsClearRequested}
                                            onSuggestionsFetchRequested={this.onCompanyTemplateSuggestionsFetchRequested}
                                            getSuggestionValue={this.getCompanyTemplateSuggestionValue}
                                            renderSuggestion={this.renderCompanyTemplateSuggestion}
                                            inputProps={inputPropsSupplier}
                                            highlightFirstSuggestion />
                            </div>
                        </div>
                        <div className="project-timesheet-buttons">
                            <input type="submit" className="export-button" disabled={!this.canExport()} onClick={this.generateReport} value="Generate and mail report"/>
                        </div>
                        {this.props.reportRequested ?
                            <div className="export-spinner">
                                <i className="fas fa-spinner fa-spin" />
                            </div> : undefined}
                    </div>
                    : null}
            </div>
        );
    }
}

type Props = {
    user: StoreUser,
    projects: StoreProject[],
    suppliers: StoreSupplier[],
    reportRequested: boolean,
    settings: StoreSettings,
    groups : StoreGroup[],

    getProjects: () => Action,
    getSuppliers: (search: string) => Action,
    clearSuppliers: () => Action,
    generateProjectReport: (project: ProjectBasedTimesheetReportType) => Action,
    getActiveUsersWithGroups: (groupIds?: number[]) => Action,
    getGroups: () => Action,
    updateSetting: (name: string, value: any) => Action
};

type State = {
    userFilter: string,
    projectFilter: string,
    clientName: string,
    year: number,
    month: number,
    selectedProject: SuggestionInfo<StoreProject> | null | undefined,
    filteredInternalProjectManagers: StoreActiveUserGroup[],
    selectedInternalProjectManagerValue: string,
    selectedInternalProjectManager: StoreActiveUserGroup | null | undefined,
    externalProjectManager: string,
    ourReference: string,
    yourReference: string,
    contractReference: string,
    selectedTemplateSupplierValue: string,
    selectedTemplateSupplier: StoreSupplier | null | undefined,
    focusedInput: 'startDate' | 'endDate' | null
};

export default ProjectTimesheetReport;
