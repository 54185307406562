import {AnyAction, Dispatch, bindActionCreators} from 'redux';

import {ConnectedProps, connect} from 'react-redux';
import { PermissionTypes } from '../../../../types/permissionTypes';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import {clearSuppliers, clearSuppliersEmployees, getSuppliers, getSuppliersEmployees} from '../../../../actions/supplierActions';
import {clearTsCodes, getTsCodes} from '../../../../actions/tsCodeActions';
import {exportActualsCsv, exportActualsExcel, exportActualsPdf, exportTimesheetsLandscapeExcel, exportTimesheetsLandscapePdf, exportTimesheetsPdf, exportTimesheetsWeeklyExcel, exportTimesheetsWeeklyPdf } from '../../../../actions/reportActions';
import { getActiveUsersWithGroups, getVisibleSuppliers } from '../../../../actions/userActions';
import { getGroups } from '../../../../actions/groupActions';
import { updateSetting } from '../../../../actions/settingsActions';
import ActualsReport from './ActualsReport';
import ProjectTimesheetReport from './ProjectTimesheetReport';
import ProtectedRoute from '../../common/ProtectedRoute';
import React from 'react';
import ReportingContainer from './ReportingContainer';
import TimeLeftOnOrderReport from './TimeLeftOnOrderReport';
import TimesheetsLandscapeReport from './TimesheetsLandscapeReport';
import TimesheetsReport from './TimesheetsReport';
import TimesheetsWeeklyReport from './TimesheetsWeeklyReport';

import { LocationTypes } from '../../../../types/locationTypes';
import { StoreState } from '../../../../typescript/storeTypes';
import {clearProjects, generateProjectReport, getAllProjects} from '../../../../actions/projectActions';
import PermissionHelper from '../../../../helpers/permissionHelper';


export class Reporting extends React.Component<Props, State> {
    state: State = {
        monthlyOpen: false
    };

    componentDidUpdate(prevProps: Props) {
        if(prevProps.user !== this.props.user) {
            const canOpenActualsReport = PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_ACTUALS_PDF) || PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_ACTUALS_EXCEL) || PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_ACTUALS_CSV);
            const canOpenTimesheetsReport = PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_TIMESHEETS_PDF);
            const canOpenTimesheetsWeeklyReport = PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_TIMESHEETS_WEEKLY_PDF);
            const canOpenTimesheetsLandscapeReport = PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_TIMESHEETS_LANDSCAPE_PDF) || PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_TIMESHEETS_LANDSCAPE_EXCEL);
            const canOpenTimeLeftOnOrderReport = PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_TIME_LEFT_ON_ORDER);
            const canOpenTimesheetLedger = PermissionHelper.hasPermission(this.props.user, PermissionTypes.POST_PROJECT_REPORT);

            if(this.props.user.permissions.length > 0
                && !canOpenActualsReport
                && !canOpenTimesheetsReport
                && !canOpenTimesheetsWeeklyReport
                && !canOpenTimesheetsLandscapeReport
                && !canOpenTimeLeftOnOrderReport
                && !canOpenTimesheetLedger
            ) {
                this.props.history.push(LocationTypes.HOME);
                return;
            }
        }

        const path = this.props.history.location.pathname;
        if (path === LocationTypes.REPORTING) return;

        if (path.startsWith(LocationTypes.REPORTING)
                && (path !== LocationTypes.REPORTING_TIMESHEETS
                && path !== LocationTypes.REPORTING_ACTUALS
                && path !== LocationTypes.REPORTING_LANDSCAPE_TIMESHEETS
                && path !== LocationTypes.REPORTING_WEEKLY_TIMESHEETS
                && path !== LocationTypes.REPORTING_TIME_LEFT_ON_ORDER
                && path !== LocationTypes.REPORTING_PROJECT_TIMESHEET_REPORT
            )) {
            this.props.history.push(LocationTypes.REPORTING);
        }
    }

    render() {
        return (
            <div className="reporting">
                <Route exact path={LocationTypes.REPORTING} render={() => (
                    <ReportingContainer
                                user={this.props.user}
                                history={this.props.history}/>
                )}/>

                <ProtectedRoute permissionType={PermissionTypes.POST_ACTUALS_PDF} exact path={LocationTypes.REPORTING_ACTUALS} render={() => (
                    <ActualsReport
                                user={this.props.user}
                                tsCodes={this.props.tsCodes}
                                reportRequested={this.props.reportRequested}
                                settings={this.props.settings}
                                orderBy={this.props.orderBy}

                                getTsCodes={this.props.getTsCodes}
                                clearTsCodes={this.props.clearTsCodes}
                                exportActualsPdf={this.props.exportActualsPdf}
                                exportActualsExcel={this.props.exportActualsExcel}
                                exportActualsCsv={this.props.exportActualsCsv}
                                getVisibleSuppliers={this.props.getVisibleSuppliers} />
                )}/>

                <ProtectedRoute permissionType={PermissionTypes.POST_TIMESHEETS_PDF} exact path={LocationTypes.REPORTING_TIMESHEETS} render={() => (
                    <TimesheetsReport
                                user={this.props.user}
                                tsCodes={this.props.tsCodes}
                                suppliers={this.props.suppliers}
                                settings={this.props.settings}
                                reportRequested={this.props.reportRequested}

                                getSuppliers={this.props.getSuppliers}
                                clearSuppliers={this.props.clearSuppliers}
                                clearTsCodes={this.props.clearTsCodes}
                                getTsCodes={this.props.getTsCodes}
                                exportTimesheetsPdf={this.props.exportTimesheetsPdf}
                                getVisibleSuppliers={this.props.getVisibleSuppliers} />
                )}/>

                <ProtectedRoute permissionType={PermissionTypes.POST_TIMESHEETS_WEEKLY_PDF} exact path={LocationTypes.REPORTING_WEEKLY_TIMESHEETS} render={() => (
                    <TimesheetsWeeklyReport
                                user={this.props.user}
                                tsCodes={this.props.tsCodes}
                                suppliers={this.props.suppliers}
                                reportRequested={this.props.reportRequested}
                                settings={this.props.settings}

                                getSuppliers={this.props.getSuppliers}
                                clearTsCodes={this.props.clearTsCodes}
                                clearSuppliers={this.props.clearSuppliers}
                                getTsCodes={this.props.getTsCodes}
                                exportWeeklyTimesheetsPdf={this.props.exportTimesheetsWeeklyPdf}
                                exportWeeklyTimesheetsExcel={this.props.exportTimesheetsWeeklyExcel}
                                getVisibleSuppliers={this.props.getVisibleSuppliers} />
                )}/>

                <ProtectedRoute permissionType={PermissionTypes.POST_TIMESHEETS_LANDSCAPE_PDF} exact path={LocationTypes.REPORTING_LANDSCAPE_TIMESHEETS} render={() => (
                    <TimesheetsLandscapeReport
                        user={this.props.user}
                        settings={this.props.settings}
                        reportRequested={this.props.reportRequested}
                        suppliersEmployees={this.props.suppliersEmployees}

                        getVisibleSuppliers={this.props.getVisibleSuppliers}
                        getSuppliersEmployees={this.props.getSuppliersEmployees}
                        clearSuppliersEmployees={this.props.clearSuppliersEmployees}
                        exportTimesheetsLandscapePdf={this.props.exportTimesheetsLandscapePdf}
                        exportTimesheetsLandscapeExcel={this.props.exportTimesheetsLandscapeExcel} />
                )}/>

                <ProtectedRoute permissionType={PermissionTypes.POST_TIME_LEFT_ON_ORDER} exact path={LocationTypes.REPORTING_TIME_LEFT_ON_ORDER} render={() => (
                    <TimeLeftOnOrderReport/>
                )}/>

                <ProtectedRoute permissionType={PermissionTypes.POST_PROJECT_REPORT} exact path={LocationTypes.REPORTING_PROJECT_TIMESHEET_REPORT} render={() => (
                    <ProjectTimesheetReport
                        user={this.props.user}
                        projects={this.props.projects}
                        suppliers={this.props.suppliers}
                        settings={this.props.settings}
                        reportRequested={this.props.reportRequested}
                        groups={this.props.groups}

                        getProjects={this.props.getAllProjects}
                        getSuppliers={this.props.getSuppliers}
                        clearSuppliers={this.props.clearSuppliers}
                        generateProjectReport={this.props.generateProjectReport}
                        getActiveUsersWithGroups={this.props.getActiveUsersWithGroups}
                        getGroups={this.props.getGroups}
                        updateSetting={this.props.updateSetting} />
                )}/>
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

type State = {
    monthlyOpen: boolean
};

function mapStateToProps(state: StoreState, props: RouteComponentProps) {
    return {
        user: state.user,
        tsCodes: state.tsCodes,
        suppliers: state.suppliers.suppliers,
        reportRequested: state.calls.reportRequested,
        suppliersEmployees: state.reporting.supplierEmployees,
        settings: state.settings,
        orderBy: state.settings.defaultOrderBy,
        projects: state.projects.projects,
        groups: state.group.groups,
        history: props.history // routed
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        getTsCodes,
        getSuppliers,
        clearSuppliers,
        clearTsCodes,
        exportActualsCsv,
        exportActualsExcel,
        exportActualsPdf,
        exportTimesheetsPdf,
        exportTimesheetsLandscapePdf,
        exportTimesheetsLandscapeExcel,
        getVisibleSuppliers,
        getSuppliersEmployees,
        clearSuppliersEmployees,
        exportTimesheetsWeeklyPdf,
        exportTimesheetsWeeklyExcel,
        getAllProjects,
        clearProjects,
        getActiveUsersWithGroups,
        getGroups,
        generateProjectReport,
        updateSetting
    }, dispatch);
}

export default withRouter(connector(Reporting));
