import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import DateHelper from '../../../../helpers/dateHelper';
import MonthWeekNavigation from './MonthWeekNavigation';
import React from 'react';
import SwitchMonth from './SwitchMonth';
import TimesheetNavigationDay from './TimesheetNavigationDay';

import { getIncompleteTimesheets, getTimesheet } from '../../../../actions/timesheetActions';

import {Period} from '../../../../typescript/dateTypes';
import  {StoreState} from '../../../../typescript/storeTypes';

export class TimesheetNavigation extends React.PureComponent<Props> {
    shouldShowWeekNavigation = (monthViewSelected: boolean, period: Period, direction: number): boolean => {
        const minDate = DateHelper.getNormalizedDate(new Date(2000,1,1));
        const maxDate = DateHelper.getNormalizedDate(new Date(new Date().getFullYear() + 1, 12, 0));

        if (monthViewSelected) {
            return false;
        }
        if (direction !== -1 && direction !== 1) {
            throw new Error('invalid direction');
        }

        const monday = DateHelper.addDays(period.startDate, 7 * direction);

        return DateHelper.isBetweenDates(minDate, maxDate, monday);
    };

    getTimesheet = (startDate: Date, monthViewSelected?: boolean): void => {
        if (monthViewSelected === undefined) {
            monthViewSelected = this.props.settings.monthViewSelected;
        }

        let newStartDate = new Date();
        if (monthViewSelected) {
            newStartDate = DateHelper.firstDateOfMonth(startDate);
        }
        else {
            if (newStartDate.getMonth() === startDate.getMonth() && newStartDate.getFullYear() === startDate.getFullYear()) {
                newStartDate = DateHelper.getMonday(new Date());
            }
            else {
                if (this.props.settings.hideWeekends && DateHelper.isWeekend(DateHelper.getDay(startDate))) {
                    startDate.setDate(3);
                }

                newStartDate = DateHelper.getMonday(startDate);
            }
        }

        const newEndDate = monthViewSelected ? DateHelper.lastDateOfMonth(newStartDate) : DateHelper.addDays(newStartDate, 6);
        this.props.getTimesheet(DateHelper.getPeriod(newStartDate, newEndDate), this.props.user.impersonatedUser);
        this.props.getIncompleteTimesheets();
    };

    getPreviousWeek = () => {
        const newStartDate = DateHelper.addDays(this.props.timesheet.period.startDate, -7);
        const newEndDate = DateHelper.addDays(this.props.timesheet.period.endDate, -7);
        const newPeriod = DateHelper.getPeriod(newStartDate, newEndDate);
        this.props.getTimesheet(newPeriod, this.props.user.impersonatedUser);
        this.props.getIncompleteTimesheets();
    };

    getNextWeek = () => {
        const newStartDate = DateHelper.addDays(this.props.timesheet.period.startDate, 7);
        const newEndDate = DateHelper.addDays(this.props.timesheet.period.endDate, 7);
        const newPeriod = DateHelper.getPeriod(newStartDate, newEndDate);
        this.props.getTimesheet(newPeriod, this.props.user.impersonatedUser);
        this.props.getIncompleteTimesheets();
    };

    componentDidUpdate(prevProps: Props): void {
        if (this.props.user.impersonatedUser.employeeId === 0) return;
        if (this.props.user.impersonatedUser !== prevProps.user.impersonatedUser) {
            this.getTimesheet(this.props.timesheet.period.startDate);
        }
    }

    render() {
        return (
            <div id="js-timesheetnavigation" className="timesheet-navigation">
                <div>
                    <SwitchMonth monthViewSelected={this.props.settings.monthViewSelected}
                                period={this.props.timesheet.period}
                                hideWeekends={this.props.settings.hideWeekends}
                                getTimesheet={this.getTimesheet}/>
                </div>
                {this.shouldShowWeekNavigation(this.props.settings.monthViewSelected, this.props.timesheet.period, -1) ? <div className="timesheet-navigation-weekly" onClick={this.getPreviousWeek}><i className="fas fa-angle-left" aria-hidden="true"/></div> : null}
                <div className={'timesheet-navigation-days' + (this.props.settings.monthViewSelected ? ' month-navigation-width' : ' week-navigation-width')}>
                    {this.props.timesheet.period.days.map(day => {
                            if (!(DateHelper.isWeekend(day) && this.props.settings.hideWeekends))
                                return (<TimesheetNavigationDay key={day.dateString} day={day}/>);
                            else
                                return (this.props.settings.monthViewSelected ? <div key={day.dateString} className="weekend-collapsed"/> : null);
                    })}
                </div>
                {this.shouldShowWeekNavigation(this.props.settings.monthViewSelected, this.props.timesheet.period, 1) ? <div className="timesheet-navigation-weekly" onClick={this.getNextWeek}><i className="fas fa-angle-right" aria-hidden="true"/></div> : null}
                <div>
                    <MonthWeekNavigation settings={this.props.settings}
                                        period={this.props.timesheet.period}
                                        getTimesheet={this.getTimesheet}/>
                </div>
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = MappedProps;

function mapStateToProps(state: StoreState) {
    return {
        timesheet: state.timesheet,
        settings: state.settings,
        user: state.user
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        getTimesheet,
        getIncompleteTimesheets
    }, dispatch);
}

export default connector(TimesheetNavigation);
