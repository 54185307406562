import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';
import { FormatTypes } from '../../../types/formatTypes';
import { format, isValid, parse } from 'date-fns';
import { usePopper } from 'react-popper';
import FocusTrap from 'focus-trap-react';
import React, {ChangeEventHandler, useRef, useState} from 'react';

export default function DatePicker(props: Props) {
    const [selected, setSelected] = useState<Date>();
    const [inputValue, setInputValue] = useState<string>('');
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const popperRef = useRef<HTMLDivElement>(null);

    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start'
    });

    const closePopper = () => {
        setIsPopperOpen(false);
        buttonRef?.current?.focus();
    };

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInputValue(e.currentTarget.value);
        const date = parse(e.currentTarget.value, FormatTypes.DATE_FORMAT, new Date());
        if (isValid(date)) {
          setSelected(date);
        } else {
          setSelected(undefined);
        }
    };

    const handleButtonClick = () => {
        setIsPopperOpen(true);
    };

    const handleDaySelect: SelectSingleEventHandler = (selectedDay)  => {
        if(selectedDay !== undefined) {
            selectedDay = new Date(selectedDay.getTime() - selectedDay.getTimezoneOffset()*60*1000);
        }
        setSelected(selectedDay);
        if (selectedDay) {
            setInputValue(format(selectedDay, FormatTypes.DATE_FORMAT));
            props.onHandleChange(selectedDay);
            closePopper();
        } else {
            props.onHandleChange(undefined);
            setInputValue('');
        }
    };

    return (
        <div className="date-picker">
            <div ref={popperRef} className="date-picker-input-container">
                <input type="text"
                    className="date-picker-input-container-input"
                    placeholder={props.placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                />
                <button className="date-picker-input-container-button"
                    ref={buttonRef}
                    onClick={handleButtonClick}>
                     <i className="fas fa-calendar-alt"/>
                </button>
            </div>
            {isPopperOpen && (
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper,
                        fallbackFocus: buttonRef.current as HTMLButtonElement
                }}>
                    <div
                        tabIndex={-1}
                        style={popper.styles.popper}
                        className="dialog-sheet"
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                    >
                        <DayPicker
                            initialFocus={isPopperOpen}
                            mode="single"
                            showOutsideDays
                            showWeekNumber
                            weekStartsOn={1}
                            defaultMonth={selected}
                            selected={selected}
                            onSelect={handleDaySelect}
                        />
                    </div>
                </FocusTrap>)
                }
        </div>);
}

type Props = {
    placeholder: string,
    onHandleChange: (date: Date| undefined) => void
}
