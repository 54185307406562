import {AnyAction, Dispatch,bindActionCreators} from 'redux';
import {ConnectedProps, connect} from 'react-redux';
import { LocationTypes } from '../../../../types/locationTypes';
import {MessageTypes} from '../../../../types/messageTypes';
import {
    addEntry,
    completeTimesheet,
    deleteEntry,
    expandTimesheetCode,
    incompleteTimesheet,
    updateEntry
} from '../../../../actions/timesheetActions';
import {
    completeTimesheetCode,
    deleteTimesheetCode,
    getTimesheetsFromClientInfo,
    incompleteTimesheetCode
} from '../../../../actions/assignmentActions';
import {getReleaseNotes} from '../../../../actions/releaseNotesActions';
import {updateSetting} from '../../../../actions/settingsActions';
import AddTimesheetCodeRow from './AddTimesheetCodeRow';
import DateHelper from '../../../../helpers/dateHelper';
import MessageHelper from '../../../../helpers/messageHelper';
import React from 'react';
import TimesheetFooter from './TimesheetFooter';
import TimesheetHelper from '../../../../helpers/timesheetHelper';
import TimesheetRow from './TimesheetRow';
import TimesheetTotal from './TimesheetTotal';
import UserHelper from '../../../../helpers/userHelper';

import * as DateTypes from '../../../../typescript/dateTypes';

import {EntryLocationInfo, MultiSelectInfo} from '../../../../typescript/infoTypes';
import {MultiEdit,NavigationEvent} from '../../../../typescript/timesheetTypes';
import { PermissionTypes } from '../../../../types/permissionTypes';
import {
    StoreState,
    StoreTimesheet,
    StoreTimesheetCode,
    StoreTimesheetEntry
} from '../../../../typescript/storeTypes';
import AccessibleUserHelper from '../../../../helpers/accessibleUserHelper';
import PermissionHelper from '../../../../helpers/permissionHelper';

export class Timesheet extends React.Component<Props & MappedProps, State> {
    state: State = {
        isComplete: false,
        //permissions
        canUncomplete: false,
        canIncompleteTimesheetCode: false,
        canComplete: false,
        canCompleteTimesheetCode: false,
        canAddEntry: false,
        canUpdateEntry: false,
        canDeleteEntry: false,
        canAddTimesheetCode: false,
        canDeleteTimesheetCode: false,
        canUploadClientTimesheet: false,
        //permissions
        activeEntry: Timesheet.defaultActiveEntry,
        multiSelect: Timesheet.defaultMultiSelect,

        setActiveEntryRequested: false,
        timesheet: this.props.timesheet
    };

    static defaultActiveEntry = {
        tsCodeId: 0,
        tsCodeIndex: -1,
        dayIndex: -1,
        day: {
            dateString: '',
            dayOfWeek: -1,
            dayOfMonth: -1
        },
        level: 0
    };

    static defaultMultiSelect = {
        selecting: false,
        tsCodeId: 0,
        tsCodeIndex: -1,
        startDayIndex: -1,
        beginDayIndex: -1,
        endDayIndex: -1
    };

    multiSelectTimeoutId: any;

    onEntryChange = (entry: StoreTimesheetEntry, day: DateTypes.Day, timesheetCode: StoreTimesheetCode): void => {
        const entryInfo = {
            username: this.props.user.impersonatedUser.username,
            tsCodeId: timesheetCode.id,
            workDate: day.dateString
        };

        if (entry.id) {
            if (entry.totalHours || entry.comments || !entry.canBeDeleted) {
                this.props.updateEntry(entry, entryInfo, this.props.user);
            } else {
                this.props.deleteEntry(entry, entryInfo, this.props.user);
            }
        }
        else {
            if (entry.totalHours || entry.comments) {
                this.props.addEntry(entry, entryInfo, this.props.user);
            }
        }
    };

    completeTheTimesheet = (): void => {
        this.props.completeTimesheet({
            username: this.props.user.impersonatedUser.username,
            period: this.props.timesheet.period
        }, this.props.user);
    };

    completeTimesheetCode = (tsCodeId: number): void => {
        this.props.completeTimesheetCode({
            username: this.props.user.impersonatedUser.username,
            month: this.props.timesheet.period.month,
            year: this.props.timesheet.period.year,
            tsCodeId: tsCodeId
        }, this.props.user);
    };

    deleteTimesheetCode = (tsCodeId: number): void => {
        this.props.deleteTimesheetCode({
            username: this.props.user.impersonatedUser.username,
            month: this.props.timesheet.period.month,
            year: this.props.timesheet.period.year,
            tsCodeId: tsCodeId
        }, this.props.user);
    };

    incompleteTimesheetCode = (tsCodeId: number): void => {
        this.props.incompleteTimesheetCode({
            username: this.props.user.impersonatedUser.username,
            month: this.props.timesheet.period.month,
            year: this.props.timesheet.period.year,
            tsCodeId: tsCodeId
        }, this.props.user);
    };

    incompleteTheTimesheet = (): void => {
        this.props.incompleteTimesheet({
            username: this.props.user.impersonatedUser.username,
            period: this.props.timesheet.period
        }, this.props.user);
    };

    setActiveEntry = (activeEntry: EntryLocationInfo): void => {
        if (this.state.multiSelect !== Timesheet.defaultMultiSelect && activeEntry !== Timesheet.defaultActiveEntry) {
            this.setState({multiSelect: Timesheet.defaultMultiSelect});
        }

        if (TimesheetHelper.changeInEntryLocation(this.state.activeEntry, activeEntry)) {
            this.setState({activeEntry});
        }
    };

    resetToDefaultEntry = (): void => {
        this.setActiveEntry(Timesheet.defaultActiveEntry);
    };

    getBeginDay = (): number => {
        let beginDay;
        if (this.props.settings.hideWeekends) {
            beginDay = this.props.timesheet.period.weekDayIndexes[0];
        } else {
            beginDay = 0;
        }

        return beginDay;
    };

    getEndDay = (): number => {
        let endDay;
        if (this.props.settings.hideWeekends) {
            endDay = this.props.timesheet.period.weekDayIndexes[this.props.timesheet.period.weekDayIndexes.length - 1];
        } else {
            endDay = this.props.timesheet.period.days.length - 1;
        }

        return endDay;
    };

    navigateTimesheetCodeUp = (sourceEntry: EntryLocationInfo, keepLevel = false, didMoveLeft = false): boolean => {
        const beginDay = this.getBeginDay(), endDay = this.getEndDay();

        const orderedTimesheetCodes = this.props.timesheet.timesheetCodes;
        for (let i = sourceEntry.tsCodeIndex - 1; i >= 0; i--) {
            if (!orderedTimesheetCodes[i].isReadOnly &&
                orderedTimesheetCodes[i].isTsCodeActive) {

                if(didMoveLeft) {
                    let indexFound = -1;
                    for(let j = endDay; j >= beginDay; j--){
                        const dateStringToUse = this.props.timesheet.period.days[j].dateString;
                        if (!TimesheetHelper.isTimesheetCodeCompleted(orderedTimesheetCodes[i], DateHelper.getNormalizedDate(new Date(dateStringToUse)))) {
                            indexFound = j;
                            break;
                        }
                    }

                    if (indexFound === -1) {
                        continue;
                    }

                    sourceEntry.dayIndex = indexFound;
                }
                else {
                    if (TimesheetHelper.isTimesheetCodeCompleted(orderedTimesheetCodes[i], DateHelper.getNormalizedDate(new Date(sourceEntry.day.dateString)))) {
                        continue;
                    }
                }

                sourceEntry.tsCodeIndex = i;
                sourceEntry.tsCodeId = orderedTimesheetCodes[i].id;
                if (orderedTimesheetCodes[i].expanded) {
                    if (!keepLevel) {
                        sourceEntry.level = 5;
                    }
                } else {
                    sourceEntry.level = 1;
                }
                return true;
            }
        }
        return false;
    };

    navigateTimesheetCodeDown = (sourceEntry: EntryLocationInfo, keepLevel = false, didMoveRight = false): boolean => {
        const beginDay = this.getBeginDay(), endDay = this.getEndDay();

        const orderedTimesheetCodes = this.props.timesheet.timesheetCodes;
        for (let i = sourceEntry.tsCodeIndex + 1; i < orderedTimesheetCodes.length; i++) {
            if (!orderedTimesheetCodes[i].isReadOnly &&
                orderedTimesheetCodes[i].isTsCodeActive) {

                if(didMoveRight) {
                    let indexFound = -1;
                    for(let j = beginDay; j <= endDay; j++){
                        const dateStringToUse = this.props.timesheet.period.days[j].dateString;
                        if (!TimesheetHelper.isTimesheetCodeCompleted(orderedTimesheetCodes[i], DateHelper.getNormalizedDate(new Date(dateStringToUse)))) {
                            indexFound = j;
                            break;
                        }
                    }

                    if (indexFound === -1) {
                        continue;
                    }

                    sourceEntry.dayIndex = indexFound;
                }
                else {
                    if (TimesheetHelper.isTimesheetCodeCompleted(orderedTimesheetCodes[i], DateHelper.getNormalizedDate(new Date(sourceEntry.day.dateString)))) {
                        continue;
                    }
                }

                sourceEntry.tsCodeIndex = i;
                sourceEntry.tsCodeId = orderedTimesheetCodes[i].id;
                if (orderedTimesheetCodes[i].expanded) {
                    if (!keepLevel) {
                        sourceEntry.level = 1;
                    }
                } else {
                    sourceEntry.level = 1;
                }
                return true;
            }
        }
        return false;
    };

    isDayCompleted = (timesheetCode: StoreTimesheetCode, day: DateTypes.Day, direction: number): boolean => {
        if(direction !== -1 && direction !== 1) {
            throw new Error('invalid direction');
        }
        const newDate = DateHelper.addDays(DateHelper.getNormalizedDate(new Date(day.dateString)), direction);
        if(DateHelper.isBetweenDates(this.props.timesheet.period.startDate, this.props.timesheet.period.endDate, newDate)) {
            return TimesheetHelper.isTimesheetCodeCompleted(timesheetCode, newDate);
        }
        return true;
    };

    onNavigateEntry = (event: NavigationEvent, sourceEntry: EntryLocationInfo): void => {
        const orderedTimesheetCodes = this.props.timesheet.timesheetCodes;
        const timesheetCode: StoreTimesheetCode = orderedTimesheetCodes[sourceEntry.tsCodeIndex];
        const entryDate = new Date(sourceEntry.day.dateString);
        const entryMonth = entryDate.getMonth() + 1;
        const entryYear = entryDate.getFullYear();
        const timesheetMonth = this.props.timesheet.period.endDate.getMonth() + 1;

        const canBeNavigatedTo = !timesheetCode.isReadOnly
                                    && !TimesheetHelper.isTimesheetCodeCompleted(timesheetCode, DateHelper.getNormalizedDate(new Date(sourceEntry.day.dateString)))
                                    && timesheetCode.isTsCodeActive
                                    && AccessibleUserHelper.isAccessibleUser(this.props.user)
                                    && !TimesheetHelper.isTimesheetEntryForCompletedMonth(entryMonth, entryYear, timesheetMonth, this.props.timesheet.incompleteMonths);

        if (event.type === 'click') {
            if (canBeNavigatedTo && (this.state.canAddEntry && this.state.canUpdateEntry && this.state.canDeleteEntry)) {
                this.setActiveEntry(sourceEntry);
            } else {
                this.setActiveEntry(Timesheet.defaultActiveEntry);
            }
        } else if (event.type === 'keydown') {
            if (!timesheetCode.isReadOnly && timesheetCode.isTsCodeActive && AccessibleUserHelper.isAccessibleUser(this.props.user)) {
                if (TimesheetHelper.didMoveUp(event)) {
                    event.preventDefault();

                    if (sourceEntry.level > 2) {
                        sourceEntry.level -= 1;
                        this.setActiveEntry(sourceEntry);
                    } else if (sourceEntry.level === 2) {
                        if (TimesheetHelper.hasExtraHours(TimesheetHelper.getEntry(timesheetCode, this.props.timesheet.period.days[sourceEntry.dayIndex])) && timesheetCode.isTsCodeActive) {
                            if (this.navigateTimesheetCodeUp(sourceEntry)) {
                                this.setActiveEntry(sourceEntry);
                            }
                        } else {
                            sourceEntry.level -= 1;
                            this.setActiveEntry(sourceEntry);
                        }
                    } else if (this.navigateTimesheetCodeUp(sourceEntry)) {
                        this.setActiveEntry(sourceEntry);
                    }
                } else if (TimesheetHelper.didMoveDown(event)) {
                    event.preventDefault();

                    if (timesheetCode.expanded && sourceEntry.level < 5) {
                        sourceEntry.level += 1;
                        this.setActiveEntry(sourceEntry);

                    } else if (this.navigateTimesheetCodeDown(sourceEntry)) {
                        this.setActiveEntry(sourceEntry);
                    }
                } else if (TimesheetHelper.didMoveLeft(event) || TimesheetHelper.didMoveRight(event)) {
                    event.preventDefault();

                    const beginDay = this.getBeginDay(), endDay = this.getEndDay();

                    if (TimesheetHelper.didMoveLeft(event)) {
                        if (sourceEntry.dayIndex === beginDay) {
                            if (this.navigateTimesheetCodeUp(sourceEntry, true, true)) {
                                this.setActiveEntry(sourceEntry);
                            }
                        } else {
                            if (this.isDayCompleted(timesheetCode, sourceEntry.day, -1)) {
                                if (this.navigateTimesheetCodeUp(sourceEntry, true, true)) {
                                    this.setActiveEntry(sourceEntry);
                                }
                            } else {
                                if (this.props.settings.hideWeekends) {
                                    sourceEntry.dayIndex = this.props.timesheet.period.weekDayIndexes[this.props.timesheet.period.weekDayIndexes.findIndex(index => index === sourceEntry.dayIndex) - 1];
                                } else {
                                    sourceEntry.dayIndex -= 1;
                                }
                                this.setActiveEntry(sourceEntry);
                            }
                        }
                    }
                    if (TimesheetHelper.didMoveRight(event)) {
                        if (sourceEntry.dayIndex === endDay) {
                            if (this.navigateTimesheetCodeDown(sourceEntry, true, true)) {
                                this.setActiveEntry(sourceEntry);
                            }
                        } else {
                            if (this.isDayCompleted(timesheetCode, sourceEntry.day, 1)) {
                                if (this.navigateTimesheetCodeDown(sourceEntry, true, true)) {
                                    this.setActiveEntry(sourceEntry);
                                }
                            } else {
                                if (this.props.settings.hideWeekends) {
                                    sourceEntry.dayIndex = this.props.timesheet.period.weekDayIndexes[this.props.timesheet.period.weekDayIndexes.findIndex(index => index === sourceEntry.dayIndex) + 1];
                                } else {
                                    sourceEntry.dayIndex += 1;
                                }
                                this.setActiveEntry(sourceEntry);
                            }
                        }
                    }
                } else if (TimesheetHelper.didExpand(event)) {
                    event.preventDefault();
                    this.onTimesheetCodeExpand(timesheetCode, true);
                }
            }
        } else if (event.type === 'mousedown' && event.button === 0) {
            if (canBeNavigatedTo) {
                event.preventDefault();

                this.multiSelectTimeoutId = setTimeout(() => {
                    this.setActiveEntry(Timesheet.defaultActiveEntry);
                    this.setState({
                        multiSelect: {
                            selecting: true,
                            tsCodeIndex: sourceEntry.tsCodeIndex,
                            startDayIndex: sourceEntry.dayIndex,
                            beginDayIndex: sourceEntry.dayIndex,
                            endDayIndex: sourceEntry.dayIndex
                        }
                    });
                }, 150);
            }
        } else if (event.type === 'mouseenter') {
            if (this.state.multiSelect.selecting) {
                if (sourceEntry.dayIndex >= this.state.multiSelect.startDayIndex) {
                    this.setState({
                        multiSelect: Object.assign({}, this.state.multiSelect, {
                            beginDayIndex: this.state.multiSelect.startDayIndex,
                            endDayIndex: sourceEntry.dayIndex
                        })
                    });
                } else {
                    this.setState({
                        multiSelect: Object.assign({}, this.state.multiSelect, {
                            beginDayIndex: sourceEntry.dayIndex,
                            endDayIndex: this.state.multiSelect.startDayIndex
                        })
                    });
                }
            }
        } else if (event.type === 'mouseup' || event.type === 'mouseleave') {
            clearTimeout(this.multiSelectTimeoutId);
        }
    };

    onTimesheetCodeExpand = (timesheetCode: StoreTimesheetCode, fromEntry?: boolean): void => {
        if (!timesheetCode.isReadOnly) {
            if (fromEntry) {
                if (timesheetCode.expanded) {
                    this.setState({activeEntry: Object.assign({}, this.state.activeEntry, {level: 1})});
                } else {
                    this.setState({activeEntry: Object.assign({}, this.state.activeEntry, {level: 2})});
                }
            } else {
                this.setActiveEntry(Timesheet.defaultActiveEntry);
            }
            this.props.expandTimesheetCode(timesheetCode);
        }
    };

    onMultiEditSubmit = (multiEdit: MultiEdit): void => {
        const timesheetCode = this.orderTimesheetCodes()[this.state.multiSelect.tsCodeIndex];

        for (let i = this.state.multiSelect.beginDayIndex; i <= this.state.multiSelect.endDayIndex; i++) {
            const day = this.props.timesheet.period.days[i];
            if (this.props.settings.hideWeekends && DateHelper.isWeekend(day)) continue;

            const entry = Object.assign({}, TimesheetHelper.getEntry(timesheetCode, day));
            entry.comments = multiEdit.comments;
            entry.normalHours = parseFloat(multiEdit.normalHours ? multiEdit.normalHours : '0');
            entry.totalHours = entry.normalHours + entry.extraHours + entry.standByHours + entry.interventionHours;

            this.onEntryChange(entry, day, timesheetCode);
        }
    };

    onMultiEditCancel = (): void => {
        this.setState({multiSelect: Timesheet.defaultMultiSelect});
    };

    orderTimesheetCodes = (): StoreTimesheetCode[] => {
        if(this.props.settings.timesheetCodeOrder) {
            return TimesheetHelper.orderTsCodesByAlphabetical(this.props.timesheet.timesheetCodes);
        }

        return TimesheetHelper.orderTsCodesByLastUsed(this.props.timesheet.timesheetCodes);
    };

    openReleaseNotes = () => {
        MessageHelper.dismiss(this.questionId);
        this.props.history.push(LocationTypes.RELEASE_NOTES);
    };

    releaseNotesMessage = () => (
        <div onClick={this.openReleaseNotes}>There are new release notes available. Click to open.</div>
    );

    questionReleaseNoteId: string|number = 0;
    questionId: string|number = 0;

    static getDerivedStateFromProps(nextProps: Props & MappedProps, state: State) {
        let newState = null;
        if(state.timesheet !== nextProps.timesheet) {
            newState = Object.assign({}, {
                isComplete: TimesheetHelper.isTimesheetComplete(nextProps.timesheet),
                timesheet: nextProps.timesheet
            });
        }

        const isImpersonating = UserHelper.isImpersonating(nextProps.user);
        const permissionTimesheetIncomplete = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_TIMESHEET_INCOMPLETE)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_TIMESHEET_INCOMPLETE_CURRENT_USER));
        const permissionTimesheetCodeIncomplete = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ASSIGNMENT_INCOMPLETE)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ASSIGNMENT_INCOMPLETE_CURRENT_USER));
        const permissionTimesheetComplete = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_TIMESHEET_COMPLETE)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_TIMESHEET_COMPLETE_CURRENT_USER));
        const permissionTimesheetCodeComplete = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ASSIGNMENT_COMPLETE)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ASSIGNMENT_COMPLETE_CURRENT_USER));
        const permissionAddEntry =  (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ACTUAL_WORK)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ACTUAL_WORK_CURRENT_USER));
        const permissionUpdateEntry = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.PUT_ACTUAL_WORK)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.PUT_ACTUAL_WORK_CURRENT_USER));
        const permissionDeleteEntry = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.DELETE_ACTUAL_WORK)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.DELETE_ACTUAL_WORK_CURRENT_USER));
        const permissionAddTimesheetCode = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ASSIGNMENT)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_ASSIGNMENT_CURRENT_USER));
        const permissionDeleteTimesheetCode = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.DELETE_ASSIGNMENT)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.DELETE_ASSIGNMENT_CURRENT_USER));
        const permissionUploadClientTimesheet = (isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_TIMESHEET_CLIENT)) || (!isImpersonating && PermissionHelper.hasPermission(nextProps.user, PermissionTypes.POST_TIMESHEET_CLIENT_CURRENT_USER));

        newState =  Object.assign(newState === null ? {} : newState, {
            canUncomplete: permissionTimesheetIncomplete,
            canIncompleteTimesheetCode: permissionTimesheetCodeIncomplete,
            canComplete: permissionTimesheetComplete,
            canCompleteTimesheetCode: permissionTimesheetCodeComplete,
            canAddEntry: permissionAddEntry,
            canUpdateEntry: permissionUpdateEntry,
            canDeleteEntry: permissionDeleteEntry,
            canAddTimesheetCode: permissionAddTimesheetCode,
            canDeleteTimesheetCode: permissionDeleteTimesheetCode,
            canUploadClientTimesheet: permissionUploadClientTimesheet
        });

        return newState;
    }

    componentDidUpdate(prevProps: Props & MappedProps): void {
        if (prevProps.timesheet !== this.props.timesheet) {
            if (this.props.releaseNotes) {
                const version = parseInt(process.env.BUILD_VERSION && process.env.BUILD_VERSION.replace(new RegExp('\\.','g'), ''));
                const storeVersion = parseInt(prevProps.releaseNotes.versionNumber && prevProps.releaseNotes.versionNumber.replace(new RegExp('\\.','g'), ''));
                if (prevProps.settings.lastSeenReleaseNotes < version && storeVersion === version) {
                    //there are new release notes (popup + edit lastSeen)
                    this.questionReleaseNoteId = MessageHelper.showMessage({
                        type: MessageTypes.QUESTION_RELEASENOTES,
                        message: this.releaseNotesMessage
                    }, this.questionReleaseNoteId);
                    prevProps.updateSetting('lastSeenReleaseNotes', version);
                }
            } else {
                this.props.getReleaseNotes();
            }

            if (AccessibleUserHelper.isAccessibleUser(this.props.user) && prevProps.timesheet.period !== this.props.timesheet.period && this.props.timesheet.timesheetCodes.filter(t => !t.isVacationOrIllness && !t.isHoliday && !t.isReadOnly).length === 0) {
                this.questionId = MessageHelper.showEmptyTimesheetMessage(this.questionId);
            } else if(this.props.timesheet.timesheetCodes.filter(t => !t.isVacationOrIllness && !t.isHoliday && !t.isReadOnly).length) {
                MessageHelper.dismiss(this.questionId);
            }
        }
    }

    getTimesheetsFromClientInfo = (assignmentId: number): void => {
        this.props.getTimesheetsFromClientInfo(assignmentId);
    };

    getTimesheetRows = (): React.ReactNode[] => {
        return this.orderTimesheetCodes().map((timesheetCode, index) => {
            return (
                <TimesheetRow key={`${timesheetCode.id}_${index}`}
                              timesheetCode={timesheetCode}
                              user={this.props.user}
                              days={this.props.timesheet.period.days}
                              period={this.props.timesheet.period}
                              settings={this.props.settings}
                              timesheetsFromClient={this.props.timesheetsFromClient[timesheetCode.assignmentIds[0]] !== undefined ? this.props.timesheetsFromClient[timesheetCode.assignmentIds[0]] : []}
                              onEntryChange={this.onEntryChange}
                              onTimesheetCodeExpand={this.onTimesheetCodeExpand}
                              canIncomplete={this.state.canIncompleteTimesheetCode}
                              canComplete={this.state.canCompleteTimesheetCode}
                              incompleteTimesheetCode={this.incompleteTimesheetCode}
                              completeTimesheetCode={this.completeTimesheetCode}
                              deleteTimesheetCode={this.deleteTimesheetCode}
                              getTimesheetsFromClientInfo={this.getTimesheetsFromClientInfo}
                              incompleteMonths={this.props.timesheet.incompleteMonths}

                              tsCodeIndex={index}
                              onNavigateEntry={this.onNavigateEntry}

                              activeEntry={this.state.activeEntry.tsCodeId === timesheetCode.id ? this.state.activeEntry : Timesheet.defaultActiveEntry}
                              setActiveEntryRequested={this.state.setActiveEntryRequested}
                              multiSelect={this.state.multiSelect.tsCodeIndex === index ? this.state.multiSelect : Timesheet.defaultMultiSelect}

                              onMultiEditSubmit={this.onMultiEditSubmit}
                              onMultiEditCancel={this.onMultiEditCancel}
                              resetToDefaultEntry={this.resetToDefaultEntry}

                              entriesReadOnly={timesheetCode.isReadOnly ||
                                               (!this.state.canAddEntry || !this.state.canUpdateEntry || !this.state.canDeleteEntry)}
                              canDeleteTimesheetCode={this.state.canDeleteTimesheetCode}

                              isAccessibleUser={AccessibleUserHelper.isAccessibleUser(this.props.user)}

                              canUploadClientTimesheet={this.state.canUploadClientTimesheet} />
            );
        });
    };

    componentDidMount() {
        document.body.addEventListener('mousedown', (event: Event & { keepEntryActive?: boolean }): void => {
            if (!event.keepEntryActive) {
                this.setActiveEntry(Timesheet.defaultActiveEntry);
            }
        });

        document.body.addEventListener('mouseup', (): void => {
            if (this.state.multiSelect.selecting) {
                this.setState({multiSelect: Object.assign({}, this.state.multiSelect, {selecting: false})});
            }
        });
    }

    render() {
        return (
            <div id="js-timesheetelement" className="timesheet">
                {this.getTimesheetRows()}
                {!this.state.isComplete && this.props.settings.monthViewSelected && this.state.canAddTimesheetCode && AccessibleUserHelper.isAccessibleUser(this.props.user) ?
                    <AddTimesheetCodeRow timesheet={this.props.timesheet}
                                         settings={this.props.settings}/> : null}
                <TimesheetTotal timesheet={this.props.timesheet}
                                days={this.props.timesheet.period.days}
                                settings={this.props.settings} />
                <TimesheetFooter timesheet={this.props.timesheet}
                                 settings={this.props.settings}
                                 completionCalled={this.props.calls.timesheetCompletionCalled}
                                 completeTimesheet={this.completeTheTimesheet}
                                 incompleteTimesheet={this.incompleteTheTimesheet}
                                 isComplete={this.state.isComplete}
                                 canComplete={this.state.canComplete && AccessibleUserHelper.isAccessibleUser(this.props.user)}
                                 canUncomplete={this.state.canUncomplete && AccessibleUserHelper.isAccessibleUser(this.props.user)}
                                 user={this.props.user}
                                 maintenanceDetails={this.props.maintenanceDetails} />
            </div>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type MappedProps = ConnectedProps<typeof connector>;

type Props = {
    history: any
};

type State = {
    isComplete: boolean,

    //permissions
    canUncomplete: boolean,
    canIncompleteTimesheetCode: boolean,
    canComplete: boolean,
    canCompleteTimesheetCode: boolean,
    canAddEntry: boolean,
    canUpdateEntry: boolean,
    canDeleteEntry: boolean,
    canAddTimesheetCode: boolean,
    canDeleteTimesheetCode: boolean,
    canUploadClientTimesheet: boolean,
    //permissions

    activeEntry: EntryLocationInfo,
    multiSelect: MultiSelectInfo,

    setActiveEntryRequested: boolean,
    timesheet: StoreTimesheet
};

function mapStateToProps(state: StoreState, props: Props) {
    return {
        timesheet: state.timesheet,
        settings: state.settings,
        user: state.user,
        calls: state.calls,
        history: props.history,
        releaseNotes: state.releaseNotes,
        timesheetsFromClient: state.timesheetsFromClient,
        maintenanceDetails : state.maintenanceDetails
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({
        addEntry,
        updateEntry,
        deleteEntry,
        completeTimesheet,
        incompleteTimesheet,
        completeTimesheetCode,
        incompleteTimesheetCode,
        deleteTimesheetCode,
        expandTimesheetCode,
        updateSetting,
        getReleaseNotes,
        getTimesheetsFromClientInfo
    }, dispatch);
}

export default connector(Timesheet);
