import { DateRange, DayPicker, SelectRangeEventHandler } from 'react-day-picker';
import { FormatTypes } from '../../../types/formatTypes';
import { format} from 'date-fns';
import { usePopper } from 'react-popper';
import FocusTrap from 'focus-trap-react';
import React, {useRef, useState} from 'react';

export default function DateRangePicker(props: Props) {
    const [range, setRange] = useState<DateRange|undefined>({from: props.defaultFromDate, to: props.defaultToDate});
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const popperRef = useRef<HTMLDivElement>(null);

    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start'
    });

    const closePopper = () => {
        setIsPopperOpen(false);
        buttonRef?.current?.focus();
    };

    const handleButtonClick = () => {
        setIsPopperOpen(true);
    };

    const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined)  => {
        setRange(range);

        if(!range) {
            props.onDateRangeChange(range);
        }

        if(range?.from && range?.to) {
            props.onDateRangeChange(range);
            closePopper();
        }
    };

    const getFormattedValue = () => {
        if(!range) return '';
        if(range.from && !range.to){
            return format(range.from, FormatTypes.DATE_FORMAT);
        }

        if(!range.from && range.to){
            return ` - ${format(range.to, FormatTypes.DATE_FORMAT)}`;
        }

        return `${format(range.from as Date, FormatTypes.DATE_FORMAT)} - ${format(range.to as Date, FormatTypes.DATE_FORMAT)}`;
    };

    return (
        <div className="date-range-picker">
            <div ref={popperRef} className="date-range-picker-input-container">
                <input type="text"
                    className="date-range-picker-input-container-input"
                    value={getFormattedValue()}
                    readOnly
                />
                <button className="date-range-picker-input-container-button"
                    ref={buttonRef}
                    onClick={handleButtonClick}>
                     <i className="fas fa-calendar-alt"/>
                </button>
            </div>
            {isPopperOpen && (
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper,
                        fallbackFocus: buttonRef.current as HTMLButtonElement
                }}>
                    <div
                        tabIndex={-1}
                        style={popper.styles.popper}
                        className="dialog-sheet"
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                    >
                        <DayPicker
                            fromYear={props.minDate.getFullYear()}
                            initialFocus={isPopperOpen}
                            mode="range"
                            showWeekNumber
                            weekStartsOn={1}
                            numberOfMonths={props.numberOfMonths}
                            defaultMonth={props.defaultFromDate}
                            fromMonth={new Date(props.minDate.getFullYear(), props.minDate.getMonth(), 1)}
                            toMonth={new Date(props.maxDate.getFullYear(), props.maxDate.getMonth(), 1)}

                            selected={range}
                            onSelect={handleRangeSelect}
                        />
                    </div>
                </FocusTrap>)
                }
        </div>);
}

type Props = {
    minDate: Date,
    maxDate: Date,
    defaultFromDate: Date,
    defaultToDate: Date,
    numberOfMonths: number,
    onDateRangeChange: (dateRange: DateRange| undefined) => void
}
